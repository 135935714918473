import { useMutation, useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { QueryKeys } from '../../constants/queryKeys';
import { StorageKeys } from '../../constants/storageKeys';
import { useAppDispatch } from '../../store/hooks/hooks';
import { useLoader } from '../../store/loader';
import { customerLoggedOut } from '../../store/reducer/customer';
import { resetCustomerDataFromSession } from '../useCustomerDataFromSession';
import { ApiError } from '../../api/ApiError';

type UseLogoutCustomerProps = {
    onError?: (error: ApiError) => void;
    onSuccess?: () => void;
};

/**
 * Hook for logging out a customer
 * @param props callbacks
 * @returns useMutation result
 */
export const useLogoutCustomer = ({ onError = noop, onSuccess = noop }: UseLogoutCustomerProps) => {
    const { customerClient } = useLoader();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const logoutCustomer = () => customerClient.performSignOut();

    const onSuccessInternal = () => {
        localStorage.removeItem(StorageKeys.CUSTOMER_DATA);
        resetCustomerDataFromSession();

        dispatch(customerLoggedOut());

        queryClient.removeQueries({ queryKey: [QueryKeys.FETCH_CUSTOMER] });

        onSuccess();
    };

    return useMutation({
        mutationFn: logoutCustomer,
        onError,
        onSuccess: onSuccessInternal,
    });
};
