import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { PageviewDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { pageTypeMapper } from '../utils/pageTypeMapper';
import { getProductDataLayerFromCartLineItem } from '../utils/getProductDataLayerFromCartLineItem';
import { Category } from '../../api/types/ClientCategory';
import { getCurrentCategoryForAnalytics, getProductCategoryAndSubcategoryForDataLayer } from '../utils/categoryUtils';
import { triggerEmarsysEvent } from '../emarsys/triggerEmarsysEvent';
import { SessionStorageKeys, StorageKeys } from '../../constants/storageKeys';
import { tryJsonParse } from '../../helper/tryParseJson';
import { StorageCustomer } from '../../domain/StorageCustomer';
import { EmarsysCommand } from '../emarsys/commants';
import { triggerEmarsysReload } from '../emarsys/triggerEmarsysReload';
import { Cart } from '../../api/types/ClientCart';
import { StoreProducts } from '../../store/reducer/product';

type PageViewedData = {
    cart?: Cart;
    pageTitle: string;
    pageType: string;
    pageUri: string;
    mainCategory?: Category;
    selectedCategoryId: string;
    products: StoreProducts;
    country: string;
};

const triggerEmarsysEvents = (data: PageViewedData) => {
    const lsCustomer = localStorage.getItem(StorageKeys.CUSTOMER_DATA);
    const customer = tryJsonParse<StorageCustomer | undefined>(lsCustomer, undefined);
    const guestEmail = sessionStorage.getItem(SessionStorageKeys.GUEST_EMAIL);
    const currentEmail = customer?.email ?? guestEmail;

    const pageType = pageTypeMapper(data.pageType, data.pageUri);

    // Send customer to emarsys
    if (currentEmail) {
        triggerEmarsysEvent({ command: EmarsysCommand.SET_EMAIL, data: currentEmail });
    }

    // Send search-term to emarsys
    if (pageType === 'Search') {
        const searchParams = new URLSearchParams(data.pageUri.split('?')[1]);
        const searchTerm = searchParams.get('q');

        triggerEmarsysEvent({ command: EmarsysCommand.SEARCH_TERM, data: searchTerm ?? 'unknown' });
    } else if (pageType === 'Category' && data.mainCategory) {
        const { category, subcategory } = getCurrentCategoryForAnalytics(
            data.mainCategory,
            data.selectedCategoryId,
            'emarsysName'
        );
        const categoryPath = [category, subcategory].filter(Boolean).join(' \x3e ');

        triggerEmarsysEvent({ command: EmarsysCommand.CATEGORY, data: categoryPath });
    }
};

const createGtmDataLayer = (event: TagManagerEvent, data: PageViewedData): PageviewDataLayer => {
    const cartItems = data.cart?.lineItems?.map((lineItem) =>
        getProductDataLayerFromCartLineItem(lineItem, lineItem.quantity)
    );

    const pageType = pageTypeMapper(data.pageType, data.pageUri);

    const { country, mainCategory, selectedCategoryId, products } = data;

    const { productCategory, productSubcategory } = getProductCategoryAndSubcategoryForDataLayer({
        country,
        mainCategory,
        pageType,
        pathName: data.pageUri,
        products,
        selectedCategoryId,
    });

    return {
            cartItems: cartItems ?? [],
            productCategory,
            productSubcategory,
            event,
            pageTitle: data.pageTitle,
            pageType,
            pageUri: data.pageUri,
    };
};

export const dispatchPageViewed = (data: PageViewedData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.PAGEVIEW, data));

    triggerEmarsysReload();
    triggerEmarsysEvents(data);
};
