import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { DiscountAppliedDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

type DiscountAppliedData = {
    coupon?: string;
    coupons?: string[];
    couponSuccess: boolean;
};

const createGtmDataLayer = (event: TagManagerEvent, data: DiscountAppliedData): DiscountAppliedDataLayer => ({
        event,
        ...data,
});

export const dispatchDiscountApplied = (data: DiscountAppliedData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.DISCOUNT_APPLY, data));
};
