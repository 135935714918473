import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';

const componentName = 'product-safety-pictograms';

interface Props {
    pictogramDescriptions?: Array<string>;
    pictograms?: Array<string>;
}

const getAltTextFromFileUrl = (url: string) => {
    const fileName = url.split('/').pop() ?? EMPTY_STRING;
    const fileNameWithoutEnding = fileName.split('.')[0];
    const normalizedFileName = fileNameWithoutEnding.replaceAll(/[-_]/g, ' ');

    return normalizedFileName;
};

export function ProductSafetyPictograms({ pictogramDescriptions = [], pictograms = [] }: Readonly<Props>) {
    const pictogramsWithAltText = pictograms.map((pictogram, index) => ({
        url: pictogram,
        alt: pictogramDescriptions[index] ?? getAltTextFromFileUrl(pictogram),
    }));

    return (
        <div className={componentName}>
            {pictogramsWithAltText.map(({ url, alt }, index) => (
                <div className={`${componentName}__row`} key={alt}>
                    <img className={`${componentName}__icon`} src={url} alt={alt} />
                    <span>{pictogramDescriptions[index]}</span>
                </div>
            ))}
        </div>
    );
}
