import { useMutation, useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { useLoader } from '../../store/loader';
import { ApiError } from '../../api/ApiError';
import { customerLoggedOut } from '../../store/reducer/customer';
import { QueryKeys } from '../../constants/queryKeys';
import { useDispatch } from 'react-redux';
import { StorageKeys } from '../../constants/storageKeys';

type UseChangePasswordProps = {
    onError?: (error: ApiError) => void;
    onSuccess?: () => void;
};

/**
 * Hook for changing the password
 */
export const useChangePassword = ({ onError, onSuccess = noop }: UseChangePasswordProps) => {
    const { customerClient } = useLoader();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const changePassword = ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) =>
        customerClient.changePassword(currentPassword, newPassword);

    const onSuccessInternal = () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.FETCH_CUSTOMER_SIGN_IN_STATE] });
        queryClient.removeQueries({ queryKey: [QueryKeys.FETCH_CUSTOMER] });
        localStorage.removeItem(StorageKeys.CUSTOMER_DATA);
        dispatch(customerLoggedOut());
        onSuccess();
    };

    return useMutation({
        mutationFn: changePassword,
        onError,
        onSuccess: onSuccessInternal,
    });
};
