import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { ReactElement } from 'react';
import { ImageBannerBorder, ImageBannerShadow } from '../types';

type ImageBannerStyleWrapperProps = BasePropsWithChildren & {
    borderBottom?: ImageBannerBorder;
    borderLeft?: ImageBannerBorder;
    borderRight?: ImageBannerBorder;
    borderTop?: ImageBannerBorder;
    shadowBottom?: ImageBannerShadow;
    shadowTop?: ImageBannerShadow;
    isMobile: boolean;
};

const componentName = 'image-banner-style-wrapper';

// eslint-disable-next-line complexity
const ImageBannerStyleWrapper = ({
    borderBottom,
    borderLeft,
    borderRight,
    borderTop,
    children,
    shadowBottom,
    shadowTop,
    isMobile,
}: ImageBannerStyleWrapperProps): ReactElement => {
    const shadowTopString =
        shadowTop?.offset && shadowTop?.color.length && shadowTop?.blur
            ? `inset 0px ${shadowTop.offset}px ${shadowTop.blur}px 0px ${shadowTop.color}, `
            : '';

    const shadowBottomString =
        shadowBottom?.offset && shadowBottom?.color.length && shadowBottom?.blur
            ? `inset 0px -${shadowBottom.offset}px ${shadowBottom.blur}px 0px ${shadowBottom.color}`
            : '';

    return (
        <div
            className={componentName}
            style={{
                borderBottom:
                    !isMobile && borderBottom?.color && borderBottom?.width
                        ? `${borderBottom.width}px solid ${borderBottom.color}`
                        : undefined,
                borderLeft:
                    !isMobile && borderLeft?.color && borderLeft?.width
                        ? `${borderLeft.width}px solid ${borderLeft.color}`
                        : undefined,
                borderRight:
                    !isMobile && borderRight?.color && borderRight?.width
                        ? `${borderRight.width}px solid ${borderRight.color}`
                        : undefined,
                borderTop:
                    !isMobile && borderTop?.color && borderTop?.width
                        ? `${borderTop.width}px solid ${borderTop.color}`
                        : undefined,
            }}
        >
            {children}
            {shadowBottomString.length || shadowTopString.length ? (
                <div
                    className={`${componentName}__inset-shadow`}
                    style={{
                        boxShadow: shadowTopString + shadowBottomString,
                    }}
                />
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
};

export default ImageBannerStyleWrapper;
