import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useCategory } from '../../hooks/useCategory';
import { usePage } from '../../hooks/usePage';
import { useProduct } from '../../hooks/useProduct';
import { useLocation } from 'react-router-dom';
import { pageTypeMapper } from '../../analytics/utils/pageTypeMapper';
import { StorageKeys } from '../../constants/storageKeys';
import { tryJsonParse } from '../../helper/tryParseJson';
import { getProductCategoryAndSubcategoryForDataLayer } from '../../analytics/utils/categoryUtils';
import { StorageCustomer } from '../../domain/StorageCustomer';
import { useCategories } from '../../queries/categories/useCategories';
import { useShopContext } from '../../hooks/useShopContext';
import { useCart } from '../../hooks/useCart';
import { useProject } from '../../hooks/useProject';
import { EMPTY_STRING } from '../../constants/semanticConstants';

declare global {
    export interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: Record<string, any>[];
    }
}

/**
 * Render the GTM integration and push initial values to the dataLayer.
 * Pushing data into the dataLayer has to be abstracted further in the future for deeper integration.
 * Implementation should be based on redux.
 */
export default function TagManager(): JSX.Element | null {
    const { pathname } = useLocation();

    const canUseDOM = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);
    const { cart } = useCart();
    const { country, currency, locale, location } = useShopContext();
    const page = usePage();
    const { products } = useProduct();
    const { key: projectKey, platform } = useProject();
    const { selectedCategoryId } = useCategory();
    const originalUrl = location?.href;
    const domain = new URL(originalUrl).hostname.replace('www.', EMPTY_STRING);
    const { data: categories = [] } = useCategories();

    const cartContent = {
        id: cart?.id,
        lineItems: cart?.lineItems.map((lineItem) => ({
            name: lineItem.name,
            id: lineItem.id,
            price: lineItem.price?.centAmount / 100,
            brand: lineItem.variant.attributes.brandName,
            variant: lineItem.variant.sku,
            quantity: lineItem.quantity,
        })),
    };

    const getInitialSPLPDataLayer = () => {
        const product = products[Object.keys(products)[0]]?.product;

        return {
            shop: projectKey ?? 'unknown',
            platform: platform ?? 'unknown',
            country: country ?? 'unknown',
            currency: currency ?? 'unknown',
            locale: locale ?? 'unknown',
            pageType: pageTypeMapper(page.pageType, pathname),
            cartContent: cartContent ?? 'unknown',
            productId: product?.id ?? 'unknown',
            productName: product?.name ?? 'unknown',
            productBrand: product ? product.variants[0].attributes.brandName : 'unknown',
            productPrice: product ? product.variants[0].price?.centAmount / 100 : 'unknown',
            productCategory: product && product.categories.length ? product.categories[0].name : 'unknown',
        };
    };

    const getInitialCatalogDataLayer = (customer: StorageCustomer) => {
        const pageType = pageTypeMapper(page.pageType, pathname);
        const mainCategory = categories[0];

        // Get product (sub-)category when on PDP or PLP
        const { productCategory, productSubcategory } = getProductCategoryAndSubcategoryForDataLayer({
            country,
            mainCategory,
            pageType,
            pathName: pathname,
            products,
            selectedCategoryId,
        });

        return {
            shop: projectKey ?? 'unknown',
            platform: platform ?? 'unknown',
            country: country ?? 'unknown',
            currency: currency ?? 'unknown',
            locale: locale ?? 'unknown',
            pageType,
            cartContent: cartContent ?? 'unknown',
            category: mainCategory?.name ?? 'unknown',
            userEmail: customer.email,
            userId: customer.id,
            productCategory,
            productSubcategory,
        };
    };

    const getInitialDataLayerForPlatform = (platform: string, customer: StorageCustomer) => {
        switch (platform) {
            case 'SPLP':
                return getInitialSPLPDataLayer();
            case 'Catalog':
                return getInitialCatalogDataLayer(customer);
            default:
                return getInitialCatalogDataLayer(customer);
        }
    };

    useEffect(() => {
        if (canUseDOM) {
            const lsCustomer = localStorage.getItem(StorageKeys.CUSTOMER_DATA);
            const customer = tryJsonParse<StorageCustomer>(lsCustomer, {});
            const initialDataLayer = getInitialDataLayerForPlatform(platform, customer);

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(initialDataLayer);
        }
    }, []);

    return (
        <Helmet>
            <script src={`https://cdn.tms.${domain}/bundle.js`} async></script>
        </Helmet>
    );
}
