import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { PaymentChangedDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

type PaymentChangedData = {
    step: 3;
    optionType: 'Zahlart';
    option: string;
    facebookTrackingId: string;
};

const createGtmDataLayer = (event: TagManagerEvent, data: PaymentChangedData): PaymentChangedDataLayer => {
    const { facebookTrackingId, step, option, optionType } = data;
    return {
            event,
            facebookTrackingId,
            ecommerce: {
                checkoutOption: {
                    actionField: {
                        option,
                        optionType,
                        step,
                    },
                },
            },
    };
};

export const dispatchPaymentChanged = (data: PaymentChangedData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.PAYMENT_CHANGE, data));
};
