import { BaseProps } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { ImageBannerBorder, ImageBannerProps } from '@mediashop/base/pattern/molecule/ImageBanner/types';
import { ReactElement, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import TogglePlayButton from '@mediashop/base/pattern/atom/TogglePlayButton';
import ImageBanner from '@mediashop/base/pattern/molecule/ImageBanner';
import Slider from '@mediashop/base/pattern/molecule/Slider';
import classNames from 'classnames';

const componentName = 'image-banner-slider';

type ImageBannerSliderProps = BaseProps & {
    backgroundColorHex: string;
    displayTime: number;
    imageBanners: ImageBannerProps[];
    style: 'banner' | 'contentBanner' | 'none';
    hideArrows: boolean;
};

const ImageBannerSlider = ({
    backgroundColorHex,
    displayTime,
    imageBanners,
    style,
    hideArrows,
}: ImageBannerSliderProps): ReactElement | null => {
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const isMobileOrTablet = device === 'mobile' || device === 'tablet';
    const isMobile = device === 'mobile';

    const onSlideChange = (swiper: SwiperCore) => {
        swiper.slides.forEach((slide: HTMLElement, index: number) => {
            if (index === swiper.activeIndex) {
                slide.style.opacity = '1';
            } else {
                slide.style.opacity = '0';
            }
        });
    };

    const getBorderStyle = (borderStyle: ImageBannerBorder, isMobile: boolean, backgroundColorHex: string) => {
        return !isMobile
            ? {
                  ...borderStyle,
                  color: backgroundColorHex ? backgroundColorHex : borderStyle.color,
              }
            : {};
    };

    if (!imageBanners.length) {
        return SKIP_RENDER;
    }

    return (
        <BackgroundColorWrapper hexBackgroundColor={backgroundColorHex}>
            <ContentWrapper
                activeMobile={style === 'contentBanner'}
                activeTablet={style === 'contentBanner'}
                activeDesktop={style === 'contentBanner'}
            >
                <div
                    className={classNames(componentName, {
                        [`${componentName}__arrow-buttons--content-banner`]: style === 'contentBanner',
                    })}
                >
                    {imageBanners.length > 1 ? (
                        <TogglePlayButton
                            className={`${componentName}__toggle-play-button`}
                            isPlaying={isPlaying}
                            onPlay={() => {
                                setIsPlaying(true);
                            }}
                            onPause={() => {
                                setIsPlaying(false);
                            }}
                        />
                    ) : (
                        SKIP_RENDER
                    )}

                    <Slider
                        loop
                        className={componentName}
                        slidesPerView={1}
                        showArrows={imageBanners.length > 1 && !hideArrows}
                        autoplay={{
                            delay: displayTime * 1000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: !isMobileOrTablet,
                        }}
                        effect={isMobileOrTablet ? undefined : 'slide'}
                        speed={isMobileOrTablet ? 1 : 350}
                        useCustomTransitionAnimation={isMobileOrTablet}
                        onSlideChange={(swiper) => onSlideChange(swiper)}
                        isPlaying={isPlaying}
                        animatePagination
                    >
                        {imageBanners.map((banner) => (
                            <SwiperSlide key={banner.id}>
                                <ImageBanner
                                    {...banner}
                                    style={style && style !== 'none' ? style : banner.style}
                                    backgroundColor={backgroundColorHex ? backgroundColorHex : banner.backgroundColor}
                                    borderTop={getBorderStyle(banner.borderTop, isMobile, backgroundColorHex)}
                                    borderBottom={getBorderStyle(banner.borderBottom, isMobile, backgroundColorHex)}
                                    borderLeft={getBorderStyle(banner.borderLeft, isMobile, backgroundColorHex)}
                                    borderRight={getBorderStyle(banner.borderRight, isMobile, backgroundColorHex)}
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
};

export default injectComponent('pattern.molecule.ImageBannerSlider', ImageBannerSlider);
