import CategoryMapper from './mapper/CategoryMapper';
import ApiClient from './ApiClient';
import { GetCategoriesRequest, GetCategoriesResponse } from './types/CategoryQueries';
import { GetCategoryProductsRequest, GetCategoryProductsResponse } from './types/ProductQueries';

export default class CategoryApiClient {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    async getCategories(locale: string) {
        const response = await this.apiClient.query<GetCategoriesRequest, GetCategoriesResponse>('GetCategories', {
            locale,
        });

        const mapper = new CategoryMapper();
        return mapper.mapCategory(response.categories);
    }

    getCategoryProducts(
        categoryId: string,
        country: string,
        currency: string,
        locale: string,
        limit: number,
        offset: number
    ) {
        return this.apiClient.query<GetCategoryProductsRequest, GetCategoryProductsResponse>('GetCategoryProducts', {
            categoryId,
            country,
            currency,
            limit,
            locale,
            offset,
        });
    }
}
