import { ApiError } from './ApiError';
import { QueryError } from './types/Query';
import prettifyErrorMessages from './prettifyErrorMessages';
import BaseUrlHelper from '../helper/BaseUrlHelper';
import { X_PROJECT_HEADER, CONTENT_TYPE_HEADER, X_FB_EVENT_ID } from '../constants/api';
import { ENDPOINT_PATH } from './constants';
import { v4 as uuidv4 } from 'uuid';

export type ApiClientConfig = {
    logToConsole?: boolean;
};

const defaultConfig: ApiClientConfig = {
    logToConsole: false,
};

/**
 * Return the path to the api with a given prefix.
 * Useful for projects living under a base-path.
 */
export function getEndpointWithBasePath(basePath: string): string {
    return BaseUrlHelper.addBasePathToPath(ENDPOINT_PATH, basePath);
}

/**
 * Provides access to the api-endpoint of the NestJS backend.
 */
export default class ApiClient {
    projectKey: string;
    endpoint: string;
    config: ApiClientConfig;

    constructor(projectKey: string, endpoint = ENDPOINT_PATH, config: ApiClientConfig = defaultConfig) {
        this.projectKey = projectKey;
        this.endpoint = endpoint;
        this.config = config;
    }

    async query<RequestType, ResponseType, ErrorType = string>(
        route: string,
        request?: RequestType,
        headers?: Record<string, any>
    ): Promise<ResponseType> {
        const endpoint = `${this.endpoint}/${route}`;

        const facebookTrackingId = headers?.facebookTrackingId ?? uuidv4();

        const req = await fetch(endpoint, {
            method: 'POST',
            headers: {
                [CONTENT_TYPE_HEADER]: 'application/json',
                [X_PROJECT_HEADER]: this.projectKey,
                [X_FB_EVENT_ID]: facebookTrackingId,
            },
            body: JSON.stringify(request),
        });

        const response = await req.text();

        if (!req.ok) {
            const error: QueryError = JSON.parse(response);

            if (req.status === 401) {
                // maybe we can handle the logout and redirect here
                // couldn't find a (good) solution for this yet
                throw new ApiError(`ApiClient: Unauthorized`, error);
            }

            if (Object.keys(error).length > 0) {
                if (this.config.logToConsole) {
                    console.error(`Request:\n${JSON.stringify(request)}\nResponse:\n${response}`);
                }
                throw new ApiError(`ApiClient: Server Error(s):\n${error.message}`, error);
            }
        }

        if (req.status === 204) {
            return {
                success: true,
            } as any;
        }

        let data: ResponseType;

        try {
            data = JSON.parse(response || '{}');
        } catch (error) {
            if (this.config.logToConsole) {
                console.error(`Request:\n${JSON.stringify(request)}\nResponse:\n${response}`);
            }
            throw new ApiError(`ApiClient: Server Error(s):\n${prettifyErrorMessages<ErrorType>(error)}`, error);
        }

        return data;
    }
}
