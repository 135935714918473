import { Helmet } from 'react-helmet-async';
import { injectComponent } from '@mediashop/app/component-injector';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Corporation, WithContext } from 'schema-dts';
import { useProject } from '@mediashop/app/hooks/useProject';

function StructuredData() {
    const { shippingCountries } = useProject();

    const mediashopSchema: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop GmbH',
        url: 'https://www.mediashop.tv/',
        logo: 'https://www.mediashop.tv/_img/100_100/site/binaries/content/gallery/images/mediashop/logo/mediashop_logo_4c.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '08003763606',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: 'German',
        },
        sameAs: [
            'https://www.facebook.com/mediashoptv',
            'https://www.instagram.com/mediashoptv/',
            'https://www.youtube.com/@mediashopspots',
            'https://www.linkedin.com/company/mediashop-company/',
            'https://www.pinterest.at/MediashopTV/',
        ],
    };

    return <Helmet script={[helmetJsonLdProp(mediashopSchema)]}></Helmet>;
}

export default injectComponent('pattern.template.Meta.StructuredData', StructuredData, 'mediashop');
