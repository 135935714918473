import { lazy } from 'react';
import { BaseProps, BrxRelationType } from '@mediashop/app/bloomreach/types';
import { injectComponent } from '@mediashop/app/component-injector';
import { Variant, VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { BadgeObject } from '@mediashop/catalog-base/types/badges';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import { SlugProductEntity } from '@mediashop/app/store/reducer/product';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const ProductDetailsContentMolecule = lazy(() => lazyRetry(() => import('./Content')));

export type ProductDetailsContentProps = BaseProps & {
    product?: SlugProductEntity;
    detailBadges: BadgeObject[];
    activeVariant: Variant;
    deliveryModalContent?: string;
    setVariant: (variant: Variant | null) => void;
    shippingCostsInfo?: BrxHtml;
    productRelationType?: BrxRelationType;
    infoTextForPaymentByInstallments?: BrxHtml;
    showSubscriptionProduct: boolean;
    onSubscriptionChanged: (isSubscriptionVariant: boolean) => void;
    hasSaleBadge: boolean;
    isVariantSelected: boolean;
    variantImage: VariantAsset;
    hideAddToCartBar: boolean;
};

const ProductDetailsContent = (props: ProductDetailsContentProps): JSX.Element => (
    <LazyComponent>
        <ProductDetailsContentMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.molecule.ProductDetailsContent', ProductDetailsContent, 'catalog-base');
