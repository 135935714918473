import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { CustomerLoggedInDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

type CustomerLoginData = {
    customerId: string;
    email: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { customerId, email }: CustomerLoginData
): CustomerLoggedInDataLayer => ({
        event,
        userEmail: email,
        userId: customerId,
});

export const dispatchCustomerLoggedIn = (data: CustomerLoginData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.LOGIN, data));
};
