import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxImageSetImpl, BrxText, BrxUsp } from '@mediashop/app/bloomreach/types';
import { Money } from '@mediashop/app/api/types/Money';
import {
    Cart,
    CartLineItem,
    CustomLineItem,
    MultipleShippingInfo,
    ShippingInfo,
    ShippingMode,
} from '@mediashop/app/api/types/ClientCart';
import { DiscountCodeItem, TotalPriceDiscount } from '@mediashop/app/api/types/Discount';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { InfoBannerCustomProps } from '@mediashop/base/pattern/molecule/InfoBannerCustom';

const CartSummaryMolecule = lazy(() => lazyRetry(() => import('./CartSummary')));

export type CartSummaryProps = BaseProps & {
    boxShadow?: boolean;
    cartMarketingInfo?: InfoBannerCustomProps;
    customFields?: Cart['customFields'];
    customLineItems: Array<CustomLineItem>;
    customerIsMember?: boolean;
    discountCodes: Array<DiscountCodeItem>;
    discountOnTotalPrice?: TotalPriceDiscount;
    handleToCheckoutClick: () => void;
    isLoading?: boolean;
    isSticky?: boolean;
    lineItems: Array<CartLineItem>;
    paymentLogos: Array<BrxImageSetImpl>;
    productTotal: Money;
    shipping?: Array<MultipleShippingInfo>;
    shippingCostInfo?: BrxText;
    shippingInfo?: ShippingInfo;
    shippingMode: ShippingMode;
    showCheckoutButton?: boolean;
    showClubCardMemberSavings?: boolean;
    showFooter?: boolean;
    showSeparator?: boolean;
    showSummaryDetails?: boolean;
    showTotalSavings?: boolean;
    showVoucherInput?: boolean;
    totalPrice: Money;
    usps?: Array<BrxUsp>;
};

const CartSummary = (props: CartSummaryProps) => (
    <LazyComponent>
        <CartSummaryMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.CartSummary', CartSummary, 'catalog-base');
