import { injectComponent } from '@mediashop/app/component-injector';
import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BrXImageVariant } from '../../../types/imageVariants';
import BloomreachAssets from '../../atom/BloomreachAssets';
import ContentWrapper from '../../atom/ContentWrapper';
import { LinkOpenInTab } from '../../atom/LinkOpenInTab';
import { getCoordinatesFromBrxString, getResponsiveCoordinates } from './helper';
import ImageBannerStyleWrapper from './ImageBannerStyleWrapper';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import TextCompound from '../../atom/TextCompound';
import { useBloomreachImage } from '../../../hooks/useBloomreachImage';
import { Coordinates, ImageBannerProps } from './types';

const componentName = 'image-banner';

const ImageBanner = ({
    anchor,
    desktopImage,
    mobileImage,
    style,
    link,
    tab,
    backgroundColor,
    borderBottom,
    borderLeft,
    borderRight,
    borderTop,
    shadowBottom,
    shadowTop,
    teaserBox,
}: ImageBannerProps): ReactElement | null => {
    const bannerStyle = style ? style : 'banner';
    const [device, setDevice] = useState('desktop');
    const [coordinates, setCoordinates] = useState<Coordinates | undefined>(undefined);
    const deviceType = useDeviceType();
    const isMobile = device === 'mobile';

    const teaserBoxWidth = !isMobile && teaserBox?.widthDesktop ? parseInt(teaserBox.widthDesktop, 10) : undefined;
    const teaserBoxOpacity = isMobile ? `${teaserBox?.opacityMobile}%` : `${teaserBox?.opacityDesktop}%`;

    const styles = {
        borderBottom,
        borderLeft,
        borderRight,
        borderTop,
        shadowBottom,
        shadowTop,
    };

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const imageToUse = isMobile ? mobileImage : desktopImage;
    const bloomreachImage = useBloomreachImage(imageToUse.image, BrXImageVariant.ImageBanner);

    useEffect(() => {
        const pxCoordinates = getCoordinatesFromBrxString(teaserBox?.coordinatesDesktop);
        if (bloomreachImage && pxCoordinates) {
            getResponsiveCoordinates(pxCoordinates, bloomreachImage).then((respCoordinates) => {
                setCoordinates(respCoordinates);
            });
        }
    }, [bloomreachImage, teaserBox?.coordinatesDesktop]);

    const renderBanner = (): ReactElement => {
        const bannerControl = (
            <LinkOpenInTab tab={tab} link={link}>
                <ImageBannerStyleWrapper {...styles} isMobile={isMobile}>
                    <div
                        className={classNames({
                            [`${componentName}__teaser-box--mobile-container`]:
                                isMobile && teaserBox?.positionMobile === 'Below',
                            [`${componentName}__teaser-box--mobile-container-reverse`]:
                                isMobile && teaserBox?.positionMobile === 'Above',
                        })}
                    >
                        <BloomreachAssets
                            className={componentName}
                            altText={desktopImage.altText ?? EMPTY_STRING}
                            desktopImage={desktopImage}
                            mobileImage={mobileImage}
                            variant={BrXImageVariant.ImageBanner}
                        />
                        {teaserBox && coordinates && (
                            <div
                                className={classNames(
                                    `${componentName}__teaser-box`,
                                    {
                                        [`${componentName}__teaser-box--with-box-shadow`]:
                                            !isMobile && teaserBox.dropshadowDesktop,
                                    },
                                    isMobile
                                        ? `${componentName}__teaser-box--mobile`
                                        : `${componentName}__teaser-box--desktop`
                                )}
                                style={
                                    isMobile
                                        ? {
                                              backgroundColor: teaserBox.backgroundColorHexMobile,
                                              opacity: teaserBoxOpacity,
                                          }
                                        : {
                                              left: `${coordinates?.left}%`,
                                              top: `${coordinates?.top}%`,
                                              maxHeight: `${100 - (coordinates?.top ?? 0)}%`,
                                              width: teaserBoxWidth,
                                              backgroundColor: teaserBox.backgroundColorHexDesktop,
                                              opacity: teaserBoxOpacity,
                                          }
                                }
                            >
                                <TextCompound
                                    {...teaserBox.desktopMobileText}
                                    className={`${componentName}__content`}
                                />
                            </div>
                        )}
                    </div>
                </ImageBannerStyleWrapper>
            </LinkOpenInTab>
        );

        switch (bannerStyle) {
            case 'contentBanner':
                return (
                    <div
                        style={
                            !isMobile
                                ? { backgroundColor: backgroundColor?.length === 7 ? backgroundColor : '#FFFFFF' }
                                : {}
                        }
                    >
                        <ContentWrapper>{bannerControl}</ContentWrapper>
                    </div>
                );
            case 'banner':
            default:
                return bannerControl;
        }
    };

    return (
        <div id={anchor?.id} className={classNames(componentName, `${componentName}--${bannerStyle}`)}>
            {renderBanner()}
        </div>
    );
};

export default injectComponent('pattern.molecule.ImageBanner', ImageBanner);
