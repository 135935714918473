import { Product, Variant } from '../../api/types/ClientProduct';
import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { ProductDetailsDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { PageTypes } from '../utils/pageTypeMapper';
import { getProductCategories, getProductCategory } from '../utils/categoryUtils';
import { Category } from '../../api/types/ClientCategory';
import { triggerEmarsysEvent } from '../emarsys/triggerEmarsysEvent';
import { EmarsysCommand } from '../emarsys/commants';

export type DispatchProductDetailsData = {
    product: Product;
    variant?: Variant;
    mainCategory?: Category;
    country: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { country, mainCategory, product, variant }: DispatchProductDetailsData
): ProductDetailsDataLayer => ({
        pageType: PageTypes.PRODUCT,
        event,
        ecommerce: {
            detail: {
                products: [
                    {
                        id: product?.id ?? 'unknown',
                        sku: variant?.sku ?? 'unknown',
                        groupId: product?.key ?? 'unknown',
                        name: product?.name ?? 'unknown',
                        brand: product ? product.variants[0].attributes.brandName : 'unknown',
                        price: variant ? variant.price?.centAmount / 100 : 'unknown',
                        category: getProductCategory(mainCategory, product, country).productCategory ?? '',
                        categories: getProductCategories(mainCategory, product, country),
                    },
                ],
            },
        },
});

export const dispatchProductDetails = (data: DispatchProductDetailsData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.PRODUCT_DETAILS, data));

    triggerEmarsysEvent({ command: EmarsysCommand.VIEW, data: data.product.key });
};
