import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { ApiError } from '../../api/ApiError';
import { NewsletterRegistrationErrorCode, SubscribeToNewsletterResponse } from '../../api/types/CustomerQueries';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { useLoader } from '../../store/loader';
import noop from 'lodash/noop';
import useToast from '../useToast';

type UseNewsletterRegistrationProps = {
    onError?: (error: ApiError | string) => void;
    onSuccess?: () => void;
};

export const useNewsletterRegistration = ({
    onError = noop,
    onSuccess = noop,
}: UseNewsletterRegistrationProps = {}) => {
    const { formatMessage } = useIntl();
    const { customerClient } = useLoader();
    const toast = useToast();

    const registerForNewsletter = ({
        emailAddress,
        botPrevention = EMPTY_STRING,
        channel,
    }: {
        emailAddress: string;
        botPrevention?: string;
        channel?: string;
    }) => customerClient.subscribeToNewsletter(emailAddress, botPrevention, channel);

    const onSuccessInternal = (response: SubscribeToNewsletterResponse) => {
        if (response.alreadySubscribed && !response.isSuccess) {
            const msg = formatMessage({ id: 'newsletter.alreadySubscribed' });
            toast.error(msg);
            if (onError) {
                onError(msg);
            }
            return;
        }

        toast.success(formatMessage({ id: 'newsletter.success' }));

        if (onSuccess) {
            onSuccess();
        }
    };

    const onErrorInternal = (error: ApiError) => {
        if (error.error.error === NewsletterRegistrationErrorCode.NEWSLETTER_SUBSCRIPTION_SUCCESSFUL) {
            toast.success(formatMessage({ id: 'newsletter.success' }));
        } else if (error.error.error === NewsletterRegistrationErrorCode.VALIDATION_FAILED) {
            toast.error(formatMessage({ id: 'newsletter.invalidEmail' }));
        } else {
            toast.error(formatMessage({ id: 'newsletter.error' }));
        }

        if (onError) {
            onError(error);
        }
    };

    return useMutation({
        mutationFn: registerForNewsletter,
        onError: onErrorInternal,
        onSuccess: onSuccessInternal,
    });
};
