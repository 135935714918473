import { useState } from 'react';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Attributes from '../Attributes';
import Videos from '../Videos';
import Delivery from '../Delivery';
import Downloads from '../Downloads';
import ProductDetails from '../ProductDetails';
import Ratings from '../Ratings';
import StarRating from '@mediashop/base/pattern/atom/StarRating';
import classNames from 'classnames';
import { AccordionItemProps } from './index';
import { useProductReviews } from '@mediashop/app/hooks/api/useProductReviews';
import { useIntl } from 'react-intl';
import TeaserGrid, { TeaserGridProps } from '../../TeaserGrid';
import Text, { TextProps } from '@mediashop/base/pattern/atom/Text';
import Accordion from '@mediashop/base/pattern/molecule/Accordion';
import { getProductSlug } from '@mediashop/app/helper/getProductSlug';
import ProductSafety from '../ProductSafety/ProductSafety';

function isTeaserGrid(specialContent: TeaserGridProps | TextProps): specialContent is TeaserGridProps {
    return 'teasers' in specialContent;
}

export const componentName = 'accordion-pdp-item';

// eslint-disable-next-line complexity
function AccordionPDPItem({ item, product, separatorLineColorHex, faq, specialContent }: AccordionItemProps) {
    const { formatNumber } = useIntl();
    const [isActive, setIsActive] = useState(!item.collapsedByDefault);
    const [reviewsPage, setReviewsPage] = useState(0);
    const showContent = isActive;

    const slug = typeof window !== 'undefined' ? getProductSlug(window.location.pathname) : '';
    const currentProduct =
        product?.product?.subscriptionProduct?.slug === slug ? product?.product?.subscriptionProduct : product?.product;

    // Take active variant, fallback to first one
    const activeVariant = product.selectedVariant ?? currentProduct!.variants[0];

    const stars = currentProduct?.variants[0]?.attributes?.ratingValueLocalized ?? 0;
    const reviewCount = currentProduct?.variants[0]?.attributes?.ratingCountLocalized ?? 0;

    const isRatingSection = item.accordionEntryType === 'ratings';

    const sku = currentProduct?.variants[0]?.sku ?? '0';
    const productReviews = useProductReviews({ enabled: isRatingSection, page: reviewsPage, sku });

    const manufacturer = activeVariant.attributes?.manufacturer;
    const safetyInstructions = activeVariant.attributes?.safetyInstructions;
    const hasHazardWarnings = Boolean(activeVariant.attributes?.hazardWarnings1?.length);
    const hasSafetyPictograms = Boolean(activeVariant.attributes?.productSafetyPictograms?.length);

    const hasSafetyInstructions = manufacturer || safetyInstructions || hasHazardWarnings || hasSafetyPictograms;

    return (
        <div id={item.anchor?.id} className={`${componentName}__wrapper`}>
            <div
                className={`${componentName}__column`}
                style={separatorLineColorHex ? { borderBottomColor: separatorLineColorHex } : {}}
            >
                {/** Header line */}
                <div
                    id={item.accordionEntryType}
                    className={`${componentName}__heading`}
                    onClick={() => setIsActive(!isActive)}
                >
                    {/** Title */}
                    <span>
                        {item.headlines.headline} {isRatingSection && `(${formatNumber(reviewCount)})`}
                    </span>

                    {/** Icons & Ratings */}
                    <div
                        className={classNames(`${componentName}__heading-text`, {
                            [`${componentName}__heading--ratings`]: item.accordionEntryType === 'ratings',
                        })}
                    >
                        {isRatingSection && reviewCount > 0 ? (
                            <div className={`${componentName}__ratings-wrapper`}>
                                <StarRating className={`${componentName}__rating`} rating={stars} />
                            </div>
                        ) : (
                            SKIP_RENDER
                        )}

                        <Icon className={`${componentName}__chevron`} name={isActive ? 'ChevronUp' : 'ChevronDown'} />
                    </div>
                </div>

                {/** Content */}
                {showContent && (
                    <div className={`${componentName}__item`}>
                        {item.accordionEntryType === 'details' && <ProductDetails product={currentProduct} />}
                        {item.accordionEntryType === 'ratings' && (
                            <Ratings
                                {...productReviews}
                                currentPage={reviewsPage}
                                product={currentProduct}
                                transitionToPage={setReviewsPage}
                            />
                        )}
                        {item.accordionEntryType === 'deliveryContent' && (
                            <Delivery productDetails={activeVariant.productDetails} />
                        )}
                        {item.accordionEntryType === 'attributes' && <Attributes variant={activeVariant} />}
                        {item.accordionEntryType === 'downloads' && <Downloads product={currentProduct} />}
                        {item.accordionEntryType === 'videos' && <Videos attributes={activeVariant.attributes} />}
                        {item.accordionEntryType === 'faq' && faq && <Accordion {...faq} />}
                        {item.accordionEntryType === 'specialContent' &&
                            specialContent &&
                            isTeaserGrid(specialContent) && <TeaserGrid {...specialContent} />}
                        {item.accordionEntryType === 'specialContent' &&
                            specialContent &&
                            !isTeaserGrid(specialContent) && <Text {...specialContent} />}
                        {item.accordionEntryType === 'productSafety' && hasSafetyInstructions && (
                            <ProductSafety
                                attributes={activeVariant.attributes}
                                productDetails={activeVariant.productDetails}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AccordionPDPItem;
