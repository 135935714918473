import { CartContentReceivedDataLayer } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { getProductDataLayerFromCartLineItem } from '../utils/getProductDataLayerFromCartLineItem';
import { Cart } from '../../api/types/ClientCart';
import { getEmarsysCart } from '../emarsys/helpers/getEmarsysCart';
import { triggerEmarsysEvent } from '../emarsys/triggerEmarsysEvent';
import { EmarsysCommand } from '../emarsys/commants';
import { ExchangeRatesSubset } from '../../types/exchangeRates';

type CartContentReceivedData = {
    cart?: Cart;
    emarsysAccountCurrency?: string;
    exchangeRates?: ExchangeRatesSubset;
};

const createEmarsysDataLayer = ({ cart, emarsysAccountCurrency, exchangeRates }: CartContentReceivedData) =>
    getEmarsysCart(cart, emarsysAccountCurrency, exchangeRates);

const createGtmDataLayer = ({ cart }: CartContentReceivedData): CartContentReceivedDataLayer | null => {
    if (cart?.id) {
        const lineItems = cart?.lineItems.map((lineItem) =>
            getProductDataLayerFromCartLineItem(lineItem, lineItem.quantity)
        );

        return {
                id: cart.id,
                lineItems,
        };
    }

    return null;
};

export const dispatchCartContentReceived = (data: CartContentReceivedData): void => {
    const dataLayer = createGtmDataLayer(data);

    if (dataLayer) {
        triggerGtmEvent(dataLayer);
    }

    triggerEmarsysEvent({ command: EmarsysCommand.CART, data: createEmarsysDataLayer(data) });
};
