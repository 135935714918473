import { lazy } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxHeadlines, BrxImageSetImpl, BrxText, BrxUsp } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { InfoBannerCustomProps } from '@mediashop/base/pattern/molecule/InfoBannerCustom';

const SPLPCartMolecule = lazy(() => lazyRetry(() => import('./SPLPCart')));

export type SplpCartProps = BaseProps & {
    cartMarketingInfo?: InfoBannerCustomProps;
    headlines: BrxHeadlines;
    paymentLogos: BrxImageSetImpl[];
    shippingCostInfoAT?: BrxText;
    shippingCostInfoCH?: BrxText;
    shippingCostInfoDE?: BrxText;
    shippingCostInfoLI?: BrxText;
    stickySummary?: boolean;
    usps?: BrxUsp[];
};

const SPLPCart = (props: SplpCartProps): JSX.Element => (
    <LazyComponent>
        <SPLPCartMolecule {...props} />
    </LazyComponent>
);

export default injectComponent('pattern.organism.SPLPCart', SPLPCart, 'catalog-base');
