import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { AddToCartDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';
import { CartState } from '../../store/reducer/cart';
import { getProductDataLayerFromCartLineItem } from '../utils/getProductDataLayerFromCartLineItem';
import { triggerEmarsysEvent } from '../emarsys/triggerEmarsysEvent';
import { getEmarsysCart } from '../emarsys/helpers/getEmarsysCart';
import { EmarsysCommand } from '../emarsys/commants';
import { ExchangeRatesSubset } from '../../types/exchangeRates';

type AddToCartData = {
    cart: CartState;
    emarsysAccountCurrency?: string;
    exchangeRates?: ExchangeRatesSubset;
    sku: string;
    quantity: number;
    facebookTrackingId: string;
};

const createEmarsysDataLayer = ({ cart, emarsysAccountCurrency, exchangeRates }: AddToCartData) =>
    getEmarsysCart(cart.cart, emarsysAccountCurrency, exchangeRates);

const createGtmDataLayer = (
    event: TagManagerEvent,
    { cart, sku, quantity, facebookTrackingId }: AddToCartData
): AddToCartDataLayer | null => {
    const product = cart.cart?.lineItems.find((lineItem) => lineItem.variant.sku === sku);
    const cartItems = cart.cart?.lineItems?.map((lineItem) =>
        getProductDataLayerFromCartLineItem(lineItem, lineItem.quantity)
    );

    if (product) {
        return {
                event,
                facebookTrackingId,
                ecommerce: {
                    currencyCode: product?.price.currencyCode,
                    add: {
                        products: [getProductDataLayerFromCartLineItem(product, quantity)],
                    },
                },
                cartItems: cartItems ?? [],
        };
    }

    return null;
};

export const dispatchAddToCart = (data: AddToCartData): void => {
    const dataLayer = createGtmDataLayer(ANALYTICS_EVENT.ADD_TO_CART, data);

    if (dataLayer) {
        triggerGtmEvent(dataLayer);
    }

    /*
     * We can trigger the 'go' command here immediately
     * see https://dev.emarsys.com/docs/web-extend/ZG9jOjMwOTYzMDE3-cart-web-extend-command#remember
     */
    triggerEmarsysEvent({ command: EmarsysCommand.CART, data: createEmarsysDataLayer(data) });
    triggerEmarsysEvent({ command: EmarsysCommand.GO });
};
