/* eslint-disable */

/**
 * This file is included in every webpack-build of the frontend.
 * Use it to add polyfills or configuration.
 */

import browserBootstrap from './BrowserBootstrapper';
import ThemeMerger from './helper/ThemeMerger';
import { polyfill } from 'seamless-scroll-polyfill';

polyfill();

// Make sure all webpack-imports are based off the basePath.
// This only applies if we are not running the dev-server, as that may trigger a webpack-issue
// in which hot-reloading information is fetched from the publicPath instead of the dev-server
if (!(process.env.WEBPACK_SERVE === 'true' || process.env.WEBPACK_SERVE === true)) {
    __webpack_public_path__ = globalThis.__project_base_path__ ? `${globalThis.__project_base_path__}/` : '/';
}

require(process.env.THEME_PATH + '/style/index.scss');

// Import theme meta and bootstrap browser process
const theme = require(process.env.THEME_MAIN_PATH).default;
browserBootstrap(ThemeMerger.mergeWithAncestors(theme));
