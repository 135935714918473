import { injectComponent } from '@mediashop/app/component-injector';
import { ReactElement } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';

import ContentNavigationComponent from './ContentNavigation';

export type ContentNavigationProps = BaseProps & {
    backgroundColorHex: string;
    ctaColorHex: string;
    fontColorHex: string;
    lineColorHex: string;
};

const ContentNavigation = (props: ContentNavigationProps): ReactElement => <ContentNavigationComponent {...props} />;

export default injectComponent('pattern.molecule.ContentNavigation', ContentNavigation);
