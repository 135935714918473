import { QueryError } from './types/Query';
import ensureArray from '../helper/ensureArray';

/**
 * Format QueryErrors into a human-readable string.
 * @param errors
 */
export default function prettifyErrorMessages<T>(errors: QueryError<T> | QueryError<T>[]): string {
    errors = ensureArray(errors);
    return errors.map((err) => `${err?.message}`).join('\n');
}
