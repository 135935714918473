import { BrxText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';

const ShippingCostModalLazy = lazy(() => lazyRetry(() => import('./ShippingCostInfoModal')));

export type ShippingCostModalProps = BrxText & {
    isOpen: boolean;
    closeModal: () => void;
};

const ShippingCostModal = (props: ShippingCostModalProps): ReactElement => (
    <LazyComponent>
        <ShippingCostModalLazy {...props} />
    </LazyComponent>
);

export default ShippingCostModal;
