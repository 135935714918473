import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import classNames from 'classnames';
import useSessionStorage from '@mediashop/app/hooks/useSessionStorage';
import { useIntl } from 'react-intl';
import BloomreachImageResponsive from '../../atom/BloomreachImageResponsive';
import Icon from '../../atom/Icon';
import BackgroundColorWrapper from '../../atom/BackgroundColorWrapper';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { InfoBannerCommonProps } from './index';
import { BrXImageVariant } from '../../../types/imageVariants';

const componentName = 'info-banner-common';

/**
 * Component for common code of <InfoBanner /> and <InfoBannerCustom />
 */
export default function InfoBannerCommon({
    anchor,
    backgroundColor,
    dismissalKey,
    icon,
    showCloseTrigger,
    style,
    text,
    textColor,
}: InfoBannerCommonProps): JSX.Element | null {
    const intl = useIntl();
    const [showBanner, setShowBanner] = useSessionStorage(`${componentName}_${dismissalKey}`, true);
    const { isMainMenuOpen } = useShopContext();

    const hideBanner = () => {
        setShowBanner(false);
    };

    if (!showBanner || isMainMenuOpen) {
        return null;
    }

    return (
        <BackgroundColorWrapper backgroundColor={style}>
            <div
                id={anchor?.id}
                className={classNames(componentName, {
                    [`${componentName}__closeable`]: showCloseTrigger,
                    [`${componentName}__has-icon`]: Boolean(icon),
                })}
                style={{ background: backgroundColor ? backgroundColor : undefined }}
            >
                {/** Content */}
                <div className={`${componentName}__content-container`}>
                    {icon ? (
                        <BloomreachImageResponsive
                            className={`${componentName}__icon`}
                            alt={EMPTY_STRING}
                            desktopImage={icon}
                            variant={BrXImageVariant.InfoBanner}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                    {text}
                </div>

                {/** Close button */}
                {showCloseTrigger ? (
                    <div className={`${componentName}__close-button-container`}>
                        <button
                            className={`${componentName}__close-icon-container`}
                            onClick={hideBanner}
                            onKeyDown={hideBanner}
                            aria-label={intl.formatMessage({ id: 'infoBanner.closeButton' })}
                            style={{ color: textColor ? textColor : undefined }}
                        >
                            <Icon className={`${componentName}__close-icon`} name="Close" />
                        </button>
                    </div>
                ) : (
                    SKIP_RENDER
                )}
            </div>
        </BackgroundColorWrapper>
    );
}
