import ProductClient from '../../api/ProductClient';
import ProductLoader from './ProductLoader';
import CartClient from '../../api/CartClient';
import { RootStore } from '../index';
import { createContext, useContext, type Context } from 'react';
import CartLoader from './CartLoader';
import OrderClient from '../../api/OrderClient';
import OrderLoader from './OrderLoader';
import CategoryApiClient from '../../api/CategoryApiClient';
import CustomerClient from '../../api/CustomerClient';
import ApiClient, { ApiClientConfig } from '../../api/ApiClient';
import EventTrackingClient from '../../api/EventTrackingClient';

export default class Loader {
    apiClient: ApiClient;

    productClient: ProductClient;
    productLoader: ProductLoader;

    cartClient: CartClient;
    cartLoader: CartLoader;

    orderClient: OrderClient;
    orderLoader: OrderLoader;

    categoryApiClient: CategoryApiClient;

    customerClient: CustomerClient;

    eventTrackingClient: EventTrackingClient;

    constructor(store: RootStore, projectKey: string, endpoint?: string, clientConfig?: ApiClientConfig) {
        this.apiClient = new ApiClient(projectKey, endpoint, clientConfig);
        this.productClient = new ProductClient(this.apiClient, store);
        this.productLoader = new ProductLoader(this.productClient, store);
        this.cartClient = new CartClient(this.apiClient, store);
        this.cartLoader = new CartLoader(this.cartClient, this.productClient, store);
        this.orderClient = new OrderClient(this.apiClient, store);
        this.orderLoader = new OrderLoader(this.orderClient, store);
        this.categoryApiClient = new CategoryApiClient(this.apiClient);
        this.customerClient = new CustomerClient(this.apiClient, store);
        this.eventTrackingClient = new EventTrackingClient(this.apiClient, store);
    }
}

export const LoaderContext: Context<Loader> = createContext(null as any);

export const useLoader = (): Loader => useContext(LoaderContext);
