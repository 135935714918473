import { QueryError } from './types/Query';

export class ApiError<T = string | QueryError> extends Error {
    public error: QueryError<T>;

    constructor(message: string, error: QueryError<T>) {
        super(message);
        this.error = error;
    }
}
