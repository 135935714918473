import { useEffect, useState } from 'react';
import useIsClientSide from './useIsClientSide';

function tryJsonParse<T>(json: string | null, defaultValue: T): T {
    if (!json) {
        return defaultValue;
    }

    try {
        return JSON.parse(json);
    } catch {
        return defaultValue;
    }
}

export default function useSessionStorage<T>(storageKey: string, defaultValue: T): [T, (value: T) => void] {
    const isClientSide = useIsClientSide();

    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(tryJsonParse(sessionStorage.getItem(storageKey), defaultValue));
    }, [isClientSide]);

    useEffect(() => {
        if (isClientSide) {
            sessionStorage.setItem(storageKey, JSON.stringify(value));
        }
    }, [value, isClientSide]);

    return [value, setValue];
}
