import { DetailedVariant, ProductDetail } from '@mediashop/app/api/types/ClientProductDetailed';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import classNames from 'classnames';
import ProductAttributeValue from '../../ProductAttributeValue';
import HazardWarning from './HazardWarning/HazardWarning';
import { ProductSafetyPictograms } from './Pictograms/Pictograms';

const componentName = 'product-safety';

type ProductSafetyProps = BaseProps & {
    attributes: DetailedVariant['attributes'];
    productDetails: Record<string, ProductDetail>;
};

function ProductSafety({ attributes = {}, productDetails }: ProductSafetyProps): JSX.Element {
    const { label: manufacturerLabel, value: manufacturerValue } = productDetails.manufacturer ?? {};
    const { label: safetyInstructionsLabel, value: safetyInstructionsValue } = productDetails.safetyInstructions ?? {};

    const allHazardWarnings = [attributes.hazardWarnings1, attributes.hazardWarnings2, attributes.hazardWarnings3];

    const hazardWarningsPictograms1 = attributes.hazardWarningsPictograms1;
    const hazardWarningsPictograms2 = attributes.hazardWarningsPictograms2;
    const hazardWarningsPictograms3 = attributes.hazardWarningsPictograms3;

    const allHazardWarningsPictograms = [
        hazardWarningsPictograms1 ? JSON.parse(hazardWarningsPictograms1) : [],
        hazardWarningsPictograms2 ? JSON.parse(hazardWarningsPictograms2) : [],
        hazardWarningsPictograms3 ? JSON.parse(hazardWarningsPictograms3) : [],
    ];

    return (
        <div className={componentName}>
            {(manufacturerValue || safetyInstructionsValue) && (
                <div
                    className={classNames(`${componentName}__table-container`, {
                        [`${componentName}__table-container--margin-bottom`]: Boolean(allHazardWarnings?.[0]?.length),
                    })}
                >
                    {manufacturerValue && (
                        <table className={`${componentName}__table`}>
                            <tbody>
                                <tr className={`${componentName}__row`}>
                                    <td className={`${componentName}__data-label`}>{manufacturerLabel}</td>
                                    <td className={`${componentName}__data-value`}>
                                        <ProductAttributeValue value={manufacturerValue.replaceAll('\n', '<br>')} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {safetyInstructionsValue && (
                        <table className={`${componentName}__table`}>
                            <tbody>
                                <tr className={`${componentName}__row`}>
                                    <td className={`${componentName}__data-label`}>{safetyInstructionsLabel}</td>
                                    <td className={`${componentName}__data-value`}>
                                        <ProductAttributeValue value={safetyInstructionsValue} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            )}

            <ProductSafetyPictograms
                pictogramDescriptions={attributes.productSafetyPictogramDescriptions}
                pictograms={attributes.productSafetyPictograms}
            />

            {allHazardWarnings?.map((hazardWarning, index) => (
                <HazardWarning
                    key={hazardWarning}
                    descriptionChemicalArticle={attributes?.descriptionChemicalArticle?.[index]}
                    hazardWarnings={hazardWarning}
                    hazardWarningsPictograms={allHazardWarningsPictograms[index]}
                    signalWord={attributes?.signalWord?.[index] ?? EMPTY_STRING}
                />
            ))}
        </div>
    );
}

export default ProductSafety;
