import { RootStore } from '../store';
import ApiClient from './ApiClient';
import {
    PageViewedEventRequest,
    PaymentOptionSelectedEventRequest,
    PDPViewedEventRequest,
    ProceededAsGuestEventRequest,
    TrackEventContextParams,
} from './types/TrackEventQueries';

export type PageViewedEventProps = {
    name: string;
    page: string;
};

export type PDPViewedEventProps = {
    name: string;
    sku: string;
    componentCount: number;
};

export type ProceededAsGuestEventProps = {
    name: string;
};

export type PaymentOptionSelectedEventProps = {
    name: string;
    paymentMethod: string;
};

export default class EventTrackingClient {
    private apiClient: ApiClient;
    private store: RootStore;

    constructor(apiClient: ApiClient, store: RootStore) {
        this.apiClient = apiClient;
        this.store = store;
    }

    private getContextParams(): TrackEventContextParams {
        const { context } = this.store.getState();
        return {
            locale: context.locale,
            country: context.country,
            currency: context.currency,
        };
    }

    async trackPageViewedEvent(event: PageViewedEventProps): Promise<void> {
        await this.apiClient.query<PageViewedEventRequest, void>('TrackEvent', {
            ...this.getContextParams(),
            event,
        });
    }

    async trackPDPViewedEvent(event: PDPViewedEventProps): Promise<void> {
        await this.apiClient.query<PDPViewedEventRequest, void>('TrackEvent', {
            ...this.getContextParams(),
            event,
        });
    }
    async trackProceededAsGuestEvent(event: ProceededAsGuestEventProps): Promise<void> {
        await this.apiClient.query<ProceededAsGuestEventRequest, void>('TrackEvent', {
            ...this.getContextParams(),
            event,
        });
    }
    async trackPaymentOptionSelectedEvent(event: PaymentOptionSelectedEventProps): Promise<void> {
        await this.apiClient.query<PaymentOptionSelectedEventRequest, void>('TrackEvent', {
            ...this.getContextParams(),
            event,
        });
    }
}
