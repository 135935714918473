import { RootStore } from '../store';
import ApiClient from './ApiClient';
import OrderMapper from './mapper/OrderMapper';
import { Order } from './types/ClientOrder';
import { CartQueryContextParams } from './types/CartQueries';
import { CreateOrderError, CreateOrderRequest, CreateOrderResponse } from './types/OrderQueries';

export default class OrderClient {
    apiClient: ApiClient;
    store: RootStore;

    constructor(apiClient: ApiClient, store: RootStore) {
        this.apiClient = apiClient;
        this.store = store;
    }

    private getContextParams(): CartQueryContextParams {
        const { context } = this.store.getState();
        return {
            currency: context.currency,
            country: context.country,
            locale: context.locale,
        };
    }

    async createOrder(facebookTrackingId: string): Promise<Order> {
        const { locale } = this.getContextParams();
        const res = await this.apiClient.query<CreateOrderRequest, CreateOrderResponse, CreateOrderError>(
            'CreateOrder',
            {
                locale,
            },
            {
                facebookTrackingId,
            }
        );

        return OrderMapper.mapOrder(res);
    }
}
