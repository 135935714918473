import { BaseProps } from '@mediashop/app/bloomreach/types';
import { BrxHtml } from '@mediashop/app/components/BloomreachHtml';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';

const CustomerClubMembershipLazy = lazy(() => lazyRetry(() => import('./CustomerClubMembership')));

export type CustomerClubMembershipProps = BaseProps & {
    ctaTextNoMembership: string;
    text?: BrxHtml;
};

const CustomerClubMembership = (props: CustomerClubMembershipProps): ReactElement => (
    <LazyComponent>
        <CustomerClubMembershipLazy {...props} />
    </LazyComponent>
);

export default CustomerClubMembership;
