/* eslint-disable id-length */
export default {
    test: 'Hello Test in English',
    account: {
        test: 'Hallo {name} in English with Account',
        subscribeButton: '',
        unSubscribeButton: '',
        unsubscribeSuccess: '',
    },
    deal: {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        dealOver: 'The offer has expired.',
        countdownEnd: '',
    },
    mainNavigation: {
        greeting: 'Hi, {name}',
        account: 'My account',
        logout: 'Log out',
        search: 'Search for products...',
        content: 'Contents',
        loggedout: '',
        searchSuggestion: '',
        productSuggestion: '',
        showResults: '',
        resultSearch: '',
        logoLinkLabel: '',
        accountLinkLabel: '',
        burgerMenuButtonText: 'Menu',
    },
    product: {
        quantityAdvantage: 'Quantity advantage: ',
        quantityAdvantageActive: 'Quantity advantage active!',
        ratingCount: '{count} {count, plural, one {Rating} other {Ratings}}',
        unitPriceSuffix: '\u202f/\u202f{baseUnitMultiplier}\u00a0{referenceUnit}',
        priceHint: 'Price including VAT',
        deliveryHint: 'Information regarding shippingcosts',
        savedMoneySuffix: ' Savings',
        buyNow: 'Buy now',
        addToCart: 'Add to cart',
        goToPdp: 'Go to productdetailpage',
        showDetails: 'Show details',
        descriptionShowMore: 'Show more..',
        descriptionShowLess: 'Show less...',
        variantSelectorTitle: 'Pick an option:',
        emptyVariant: 'Pick an option',
        chooseVariant: ' selection',
        emptyVariantError: 'Please select an option',
        variantSize: 'Size',
        variantColor: 'Color',
        badges: {
            installments: '{count} x {count, plural, one {Installment} other {Installments}}',
            xplusygratis: '{text} FREE',
            savings: '{count}% discount',
            savingsPDP: '-{count}%',
            installmentsVariants: '',
            installmentsVariantsAsAlternative: '',
            installmentsAsAlternative: '',
            installmentsInFinalSummary: '',
            installmentsInCart: '',
            deal: '',
            mobile: {
                gratisZubehoer: '',
            },
        },
        payOnce: 'or one time',
        payOnceSum: 'Total price',
        payOnceSumMobile: 'Total',
        bundleTitle: 'Also available as a set:',
        cheaperSet: '',
        setTypeXplusY: '',
        savedMoneyBadge: '',
        detailsShowMore: '',
        detailsShowLess: '',
        suggestedPriceShort: '',
        uvp: '',
        statt: '',
        socialMediaShareHeadline: '',
        socialMediaShareText: '',
        attributes: {
            headline: '',
            subheadline: '',
            true: '',
            false: '',
            maxOrderQuantity: '',
            deliverables: '',
            name: '',
            description: '',
            usp: '',
            productDetails: '',
            claim: '',
            weightGross: '',
            articleType: '',
            articleIsBuyable: '',
            erpCategory1: '',
            erpCategory2: '',
            crmArticleBundleNo: '',
            webSubtitle: '',
            countryLicensesAvailable: '',
            articleDimensions: '',
            textilMaterialComposition: '',
            relatedUpsell: '',
            relatedUpgrade: '',
            subscriptionAvailable: '',
            subscriptionSelection: {
                day: '',
                month: '',
                week: '',
            },
            deliveryTimeFrom: '',
            deliveryTimeUntil: '',
            medicalProduct: '',
            productChemicalIngredients: '',
            signalWord: '',
            ean: '',
            eanNl: '',
            prices: '',
            biocidalProduct: '',
            shippingTimeCode: '',
            expiryDate: '',
            navBulkyItem: '',
            productSafety: '',
            perDelivery: '',
        },
        shipping: {
            dropShipment: '',
            bulkyDelivery: '',
            totalCost: '',
        },
        addedToCart: '',
        cantAddToCart: '',
        productNumber: '',
        productRelation: '',
        next: '',
        previous: '',
        deliveryProductCard: '',
        downloadFor: '',
        topReview: '',
        seeMore: '',
        seeLess: '',
        totalReviews: '',
        noReviews: '',
        videoThumbnailText: '',
        subscription: {
            oneTimeDelivery: 'One-time delivery',
            abo: 'Savings-subscription',
            emptyOption: '',
            intervalUnit: {
                day: '',
                month: '',
                year: '',
            },
            delivery: '',
            recommendedInterval: '',
        },
        exceededMaxOrderQuantity: '',
        pageTitle: '',
        productUnavailable: {
            headline: '',
            text: '',
        },
    },
    preCheckout: {
        successMessage: 'Good choice! The article was added.',
        ctaMessage: 'add to cart and',
        ctaMessageBold: 'order with free shipping!',
        accessories: 'Suitable accessories',
        goBackBtn: 'Continue shopping',
        goToCartBtn: 'Go to Cart',
        addToCartSingle: '',
        addToCart: '',
        addToCartUpgrade: '',
        relations: '',
        toProduct: '',
        deliverables: '',
        clubSuggestion: '',
        clubCardAlreadyInCart: '',
    },
    cart: {
        headline: 'Basket',
        addons: {
            headline: 'Nice addons',
            add: 'Add to order',
        },
        subtotal: 'Subtotal',
        deliveryCost: 'Delivery Cost',
        total: 'Total',
        shippedFreeOne: 'This order will be shipped ',
        shippedFreeTwo: 'FREE',
        shippedFreeThree: '!',
        vatIncluded: 'Tax included',
        savings: 'Total Savings',
        toCheckout: 'Checkout',
        goSafeToCheckout: 'Checkout',
        noReservation: '',
        articlesWithCount: '',
        allTotal: '',
        free: '',
        savingsText: '',
        xArticle: '',
        voucher: '',
        redeemVoucher: '',
        codePlaceholder: '',
        redeemCTA: '',
        voucherCodeSuccess: '',
        voucherCodeErrorCodes: {
            alreadyPresent: '',
            nonApplicable: '',
        },
        emptyCart: '',
        emptyCartMessage: '',
        emptyCartButton: '',
        emptyCartButtonMessage: '',
        voucherModal: {
            headline: '',
            headlineGiftLineItem: '',
            text: '',
            continue: '',
        },
        wkoProductTitle: '',
        wkoModalHeadline: '',
        wkoDeclineOffer: '',
        wkoAddToCartSuccess: '',
        restoreCartError: '',
        clubSavings: {
            clubSavingsText: '',
            clubSavingsLink: '',
            clubSavingsUnderline: '',
        },
        clubExtensionCta: '',
        freeShippingBanner: {
            freeShipping: '',
            headline: '',
            continue: '',
            shopping: '',
            order: '',
            freeDelivery: '',
        },
    },
    checkout: {
        dividerText: 'Or',
        billingAddressHeadline: '1. Billing Address',
        paymentHeadline: '2. Payment Method',
        summaryHeadline: '3. Summary',
        nextStep: 'Next',
        buyNow: 'Buy Now',
        cart: 'Cart',
        lineItem: {
            quantity: 'Quantity',
        },
        freeDelivery: 'This order will be delivered FREE!',
        enterAddress: 'Please enter your address before selecting a payment-method.',
        summary: {
            headline: 'Summary',
            usps: {
                moneyBack: 'Money back guarantee',
                freeDelivery: 'free shipping',
                refund: '30 day refund',
                freeReturn: 'free return',
                securePayment: 'Secure payment',
            },
            subtotal: 'Subtotal',
            deliveryCost: 'Delivery Cost',
            total: 'Total',
            taxIncluded: 'incl. Tax',
            totalSavings: 'Total Savings',
            buyNow: 'Buy now',
            deliveryTime: 'Will be delivered in {time} workdays.',
            comment: 'Message to the deliverer',
            bulkyDeliveryCost: '',
            transactionFee: '',
            transactionFeeCashOnDelivery: '',
            privacy: '',
            legal: '',
            legalDetails: {
                privacyText: '',
                privacyLink: '',
                termsText: '',
                termsLink: '',
            },
            permissionEmailPhonePost: '',
            permissionEmailPhonePostDetails: {
                link: '',
            },
            newsletterSubscription: '',
            dropShippingCost: '',
        },
        orderNotSavedYet: 'Your order has not been saved, yet. Do you really want to leave the page?',
        customerClubExtensionModal: {
            title: 'Attention',
            text:
                'You are already a member of the customer club. The customer card has been removed from your shopping cart.' +
                'Would you like to buy an extension of your existing membership instead?',
            addToCart: 'Buy extension',
            closeButton: 'Close',
        },
        subscriptionInfo:
            'You need a customer account to purchase a subscription. Either log in with your existing customer ' +
            'account or simply create a new customer account.',
        minicartEdit: '',
        allowRecurrence: {
            checkbox: '',
            error: '',
        },
        catalogBillingAddressHeadline: '',
        editBillingAddress: '',
        guestInfo: '',
        checkOrder: '',
        nextStepPayment: '',
        lastStep: '',
        addresses: {
            headline: '',
            customerLogin: '',
            guestOrder: '',
            register: '',
        },
        payment: {
            paymentFee: '',
            paymentFeeForNachnahme: '',
            noPaymentFee: '',
            noPaymentFeeForNachnahme: '',
            networkError: '',
            tryOtherNetworkError: '',
            installmentNetworkError: '',
            invoiceNetworkError: '',
            invoiceCheckDateOfBirth: '',
            invoiceAddressError: '',
            genericError: '',
            unavailable: '',
            invoice: '',
            invoiceoffline: '',
            collectminvoice: 'Invoice',
            mastercard: '',
            paypal: '',
            paypalexpress: '',
            postfinancecard: '',
            visa: '',
            sofortueberweisung: '',
            nachnahme: '',
            installment: '',
            installmentActivateCTA: '',
            installmentCheckbox: '',
            afterpayinvoice: '',
            idealr: '',
            mistercashR: '',
            unavailableShippingCountryTitle: '',
            unavailableShippingCountry: '',
            descriptions: {
                postFinanceCard: '',
                visa: '',
                mastercard: '',
                payPal: '',
                payPalPayLater: '',
                sofortueberweisung: '',
                invoice: '',
                invoiceOffline: '',
                installment: '',
                ideal: '',
                afterPay: '',
                bancontact: '',
            },
        },
        shippingAddressHeadline: '',
        resetPassword: {
            title: '',
            textBold: '',
            textNormal: '',
            textCta: '',
            resetButton: '',
            closeButton: '',
        },
        multiPage: {
            goToCartModal: {
                headline: '',
                description: '',
                dismiss: '',
                submit: '',
            },
            summary: {
                amount: '',
                billingAddress: '',
                billingAndDelivery: '',
                change: '',
                lineItemTotalPrice: '',
                paymentType: '',
                shippingAddress: '',
                title: '',
                yourOrder: '',
            },
            headlines: {
                headline: '',
                address: '',
                payment: '',
                summary: '',
            },
            nextButton: '',
            checkDataButton: '',
        },
    },
    totalPrice: {
        taxIncluded: 'Tax incl.',
    },
    address: {
        sectorPlaceholder: 'Sec',
        blockPlaceholder: 'Bl',
        stairwayPlaceholder: 'Sc',
        floorPlaceholder: 'Et',
        apartmentPlaceholder: 'Ap',
        emailPlaceholder: 'Email Address',
        salutationPlaceholder: 'Salutation',
        salutations: {
            ms: 'Ms.',
            mr: 'Mr.',
            none: 'None',
        },
        firstnamePlaceholder: 'Firstname',
        lastnamePlaceholder: 'Lastname',
        phonePlaceholder: 'Phone',
        streetPlaceholder: 'Street',
        streetNumberPlaceholder: 'Streetno',
        zipCodePlaceholder: 'ZIP',
        cityPlaceholder: 'City',
        birthdateTitle: 'Birthday',
        birthdatePlaceholder: '1990-01-23',
        billingAddress: '',
        requiredField: '',
        emailLabel: '',
        emailErrorText: '',
        salutationLabel: '',
        customerTypeLabel: '',
        customerType: {
            private: '',
            corporate: '',
        },
        contactLabel: '',
        contactPerson: '',
        contactInfo: '',
        firstnameErrorText: '',
        lastnameErrorText: '',
        nameLengthErrorText: '',
        phoneErrorText: '',
        company: '',
        companyPlaceholder: '',
        companyErrorText: '',
        vatIdPlaceholder: '',
        vatIdErrorText: '',
        address: '',
        streetErrorText: '',
        streetLengthErrorText: '',
        streetLengthErrorTextCZ: '',
        streetNumberErrorText: '',
        zipCodeErrorText: '',
        cityErrorText: '',
        country: '',
        countryLabel: '',
        countryPlaceholder: '',
        countyLabel: '',
        countyPlaceholder: '',
        packingStationPlaceholder: '',
        packingStation: {
            yes: '',
            no: '',
        },
        shippingAddressHeadline: '',
        shippingAddressPlaceholder: '',
        shippingAddress: {
            yes: '',
            no: '',
        },
        dayPlaceholder: '',
        monthPlaceholder: '',
        yearPlaceholder: '',
        birthdateErrorText: '',
        birthdateTooYoungErrorText: '',
        birthdateDayErrorText: '',
        birthdateMonthErrorText: '',
        birthdateYearErrorText: '',
        birthdayTitle: '',
        birthDayError: '',
        loqatePlaceholder: '',
        loqateManualAddress: '',
        loqateManualAddressHint: '',
        loqateSearchAddress: '',
        noResults: 'Sorry, there were no results',
    },
    checkoutSuccess: {
        headline: 'Thank You for your order',
        headlineError: 'An error has occurred',
        textFirst: 'We have received your order and will send you an additional confirmation to <link>{email}</link>.',
        textSecond: 'As soon as your order leaves our shipping-center, we will inform you once more per email.',
        textSecondPending:
            'Your order is being processed. We will notify you via E-Mail as soon as your order is created.',
        textError: 'Please contact our customer service regarding the status of your order.',
        noEmailPlaceholder: 'Your email address',
        newsletter: {
            headline: '',
            text: '',
            feedbackText: '',
            error: '',
        },
        catalog: {
            headline: '',
            text: '',
            feedbackText: '',
            error: '',
        },
    },
    country: {
        DE: 'Germany',
        AT: 'Austria',
        select: '',
        CH: '',
        LI: '',
        HU: '',
        IT: '',
        BE: '',
        NL: '',
        BG: '',
        HR: '',
        CY: '',
        CZ: '',
        DK: '',
        EE: '',
        FI: '',
        FR: '',
        GR: '',
        IE: '',
        LV: '',
        LT: '',
        LU: '',
        MK: '',
        MT: '',
        PL: '',
        PT: '',
        RO: '',
        SM: '',
        SK: '',
        SI: '',
        ES: '',
        SE: '',
    },
    socialMedia: {
        facebook: 'Share on Facebook',
        twitter: 'Share on Twitter',
        whatsapp: 'Share on WhatsApp',
        native: '',
    },
    productListing: {
        noProducts: 'No products found',
        products: '{count} {count, plural, one {product} other {products}}',
        loadMore: 'Load more',
        from: 'of',
        articles: 'Articles',
        title: '{categoryName} | {shopName}{count, plural, one {} other { - page {count}}}',
    },
    pagination: {
        ariaPage: 'Page {pageNumber}',
        next: 'Next',
        previous: 'Back',
    },
    menu: {
        logout: 'Logout',
        logoutHeader: 'Attention',
        logoutText: 'Are you sure you want to logout?',
        logoutButtonText: 'Logout',
    },
    login: {
        emailPlaceholder: 'E-Mail Address',
        passwordLabel: 'Enter Password',
        usernameEmptyError: 'Username should not be empty',
        usernameEmailError: 'Username should be a valid Email address',
        passwordEmptyError: 'Password should not be empty',
        userLoginError: 'Username or Password are incorrect',
        authorizeStoreTitle: 'Authorize Existing Credentials',
        authorizeStoreText: 'Would you like to authorize your the usage of your existing credentials ?',
        yes: 'Yes',
        no: 'No',
        password: '',
        submitButtonLabel: '',
        passwordForgotten: '',
        loginSuccess: '',
        existingUser: '',
        resetPassword: '',
        resetEmailSent: '',
        resetErrorQuestions: '',
        resetErrorAnswerOne: '',
        resetErrorAnswerTwo: '',
        resetErrorAnswerThree: '',
        newPassword: '',
        passwordNotMatchingReq: '',
        passwordsNotIdentical: '',
        repeatPassword: '',
        changePassword: '',
        passwordChanged: '',
        emailConfirmed: '',
        processToken: '',
        generalError: '',
        emailUnconfirmed: '',
    },
    orders: {
        order: 'Order',
        total: 'Total',
        details: 'Details',
        back: 'Back',
        shippingAddress: 'Shipping Address',
        paymentMethod: 'Payment method',
        paymentMethodNotApplicable: 'N/A',
        quantity: 'Quantity: {quantity}',
        reorder: 'Buy again',
        reOrderSuccess: 'Item Added Successfully',
        reOrderFail: 'Item Could not be added to the cart',
        date: '',
        progress: '',
    },
    customer: {
        customerNumber: 'Customer number',
        billingAddress: 'Billing address',
        email: 'E-mail address',
        phone: 'Phone',
        password: 'Password',
        passwordText: 'The password is not displayed for security reasons.',
        passwordLinkText: 'Forgot Password?',
        customerCardLink: {
            headline: 'Link your online account with your club card',
            subHeadline: 'Use the advantages of the customer club also for your purchases in our online store',
            cardNumber: 'Card number',
            clubEmail: 'Customer club e-mail address',
            cta: 'Connect',
            success: 'Your customer club card has been successfully linked to your account.',
            error: {
                mykeneNotFound: 'No customer club card with the specified number could be found.',
                mykeneInvalidCombination: 'The email address does not match the customer club card.',
                mykeneError: 'An error has occurred. Please try again later.',
                cardExistsForStore: 'Your customer club card has already been added to an account.',
                unknownError: 'An error has occurred. Please try again later.',
            },
        },
        mr: '',
        mrs: '',
        tokenNotReceived: '',
        emailInvalid: '',
        dataUpdated: '',
        wrongPassword: '',
        couldNotResetPassword: '',
        customerClub: {
            cardNumber: '',
            validFrom: '',
            validThru: '',
            expiryWarning: '',
            cardExpired: '',
            offerCardRenewal: '',
        },
    },
    register: {
        passwordEmptyError: 'Password cannot be empty',
        passwordLabel: 'Enter your password',
        passwordCriteriaHeader: 'Your password must ...',
        passwordCriteria: {
            numberOfCharacters: '✔ consist of at least 8 characters',
            hasNumber: '✔ contain 1 number',
            hasUppercaseLetter: '✔ contain 1 uppercase letter',
            hasLowercaseLetter: '✔ contain 1 lowercase letter',
        },
        passwordNotValidError: 'Password is not valid',
        acceptGDPRError: 'You must accept the terms and conditions',
        existingAccountModal: {
            text:
                'This e-mail address is already used. If you are having already an account for one of the following' +
                ' shops, please log in with your credentials.',
            shops: '<ul><li>haus-hobby.ch</li><li>maison-hobby.ch</li><li>haus-hobby.com</li></ul>',
            accept: 'Login',
            close: 'Close',
        },
        passwordMinError: '',
        passwordConfirmationError: '',
        passwordConfirmationLabel: '',
        nextStepPayment: '',
        error: '',
        successMessage: '',
        mainError: '',
        change: '',
        accountVerificationTitle: '',
        accountVerificationText: '',
        accountVerificationCTA: '',
        newsletterSubscription: '',
        existingAccountToast: '',
    },
    countrySwitcher: {
        headline: 'Change language and shipping options',
        country: 'Delivery to',
        language: 'Language',
        button: '',
        modal: {
            headline: '',
            description: '',
            submitText: '',
            closeText: '',
        },
    },
    subcategoryFilter: {
        all: 'Alle',
        reset: 'Clear filter',
    },
    userManuals: {
        headline: 'Manuals',
        search: 'Search by product, article number or CEE-code',
        resetSearch: 'Reset search',
        table: {
            sku: 'Article number',
            code: 'Code',
            productTitle: 'Product',
            type: 'Type',
            types: {
                instruction_manual: 'Instruction manual',
                recipe_booklet: 'Recipe booklet',
                training_plan: 'Training plan',
                nutrition_plan: 'Nutrition plan',
            },
        },
        noResults: 'No manuals found.',
    },
    price: {
        from: 'from\u00a0{price}',
        reference: '{price}\u00a0/\u00a0{baseUnitMultiplier}\u00a0{referenceUnit}',
        statt: 'instead of {price}',
        uvp: 'UVP\u00a0{price}',
    },
    cartChangeModal: {
        headline: '',
        description: '',
        submitText: '',
    },
    category: {
        showMore: '',
        breadcrumbHomeAriaLabel: '',
    },
    clubCustomer: {
        priceMessage: '',
        modal: {
            bannerHeadline: '',
            bannerSubHeadline: '',
            headline: '',
            text: '',
            button: '',
        },
    },
    county: {
        alba: '',
        arad: '',
        arges: '',
        bacau: '',
        bihor: '',
        bistritaNasaud: '',
        botosani: '',
        braila: '',
        brasov: '',
        bucuresti: '',
        buzau: '',
        calarasi: '',
        carasSeverin: '',
        cluj: '',
        constanta: '',
        covasna: '',
        dambovita: '',
        dolj: '',
        galati: '',
        giurgiu: '',
        gorj: '',
        harghita: '',
        hunedoara: '',
        ialomita: '',
        iasi: '',
        ilfov: '',
        maramures: '',
        mehedinti: '',
        mures: '',
        neamt: '',
        olt: '',
        prahova: '',
        salaj: '',
        satuMare: '',
        sibiu: '',
        suceava: '',
        teleorman: '',
        timis: '',
        tulcea: '',
        valcea: '',
        vaslui: '',
        vrancea: '',
    },
    emarsysFieldWriter: {
        success: '',
        error: '',
    },
    infoBanner: {
        closeButton: '',
    },
    newsletter: {
        inputPlaceholder: '',
        success: '',
        invalidEmail: '',
        error: '',
        emailEmptyError: '',
        registered: '',
        notRegistered: '',
        subscribe: '',
        unsubscribe: '',
        alreadySubscribed: '',
        successAccount: '',
        alreadyRegisteredQuestion: '',
        unsubscribeHere: '',
        successUnsubscribe: '',
        unsubscribeError: '',
    },
    orderSuccessPage: {
        orderSuccess: '',
        goToShop: '',
        accountQuestion: '',
        accountAdvantagesHeader: '',
        accountAdvantageOne: '',
        accountAdvantageTwo: '',
        accountAdvantageThree: '',
        password: '',
        passwordReq: '',
        passwordReqDescription: '',
        passwordReqDescTitle: '',
        passwordReqDescOne: '',
        passwordReqDescTwo: '',
        passwordReqDescThree: '',
        passwordReqDescFour: '',
        checkboxText: '',
        checkboxInfo: '',
        passwordSaveBtn: '',
    },
    personalInfo: {
        newEmail: '',
        newEmailConfirm: '',
        passwordConfirm: '',
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        actualEmail: '',
    },
    slider: {
        prevButton: '',
        nextButton: '',
        activePagination: '',
        inactivePagination: '',
    },
    specialDeal: {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        specialDealOver: '',
    },
    subscription: {
        amount: '',
        deliveryInterval: '',
        nextDelivery: '',
        ordered: '',
        status: '',
        cancelSubscription: '',
        state: {
            active: '',
            paused: '',
            cancelled: '',
            problems: '',
        },
        intervalUnit: {
            day: '',
            month: '',
            year: '',
        },
    },
    voucher: {
        codes: {
            notActive: '',
            notValid: '',
            doesNotMatchCart: '',
            maxApplicationReached: '',
            applicationStoppedByPreviousDiscount: '',
            matchesCart: '',
        },
        modal: {
            dontShowLegalText: '',
            displayLegalText: '',
            savings: '',
            headline: '',
            minimalOrderAmount: '',
        },
        priceGiftLineItem: '',
    },
    routerErrorBoundary: {
        title: 'Unable to connect to server',
        infoText: 'Please check your internet connection or try again later.',
        buttonText: 'Reload page',
        stacktraceTitle: 'Details',
    },
} satisfies IntlMessages;
