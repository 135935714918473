import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { CustomerRegisteredDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

type CustomerRegistrationData = {
    customerId: string;
    email: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    { customerId, email }: CustomerRegistrationData
): CustomerRegisteredDataLayer => ({
        event,
        userEmail: email,
        userId: customerId,
});

export const dispatchCustomerRegistered = (data: CustomerRegistrationData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.REGISTRATION, data));
};
