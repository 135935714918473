import { lazy, ReactElement } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';

const CardLinkLazy = lazy(() => lazyRetry(() => import('./CardLink')));

export type CardLinkProps = BaseProps;

const CardLink = (props: CardLinkProps): ReactElement => (
    <LazyComponent>
        <CardLinkLazy {...props} />
    </LazyComponent>
);

export default CardLink;
