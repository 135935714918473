import { ANALYTICS_EVENT } from '../constants/analyticsEvent';
import { CheckoutAccountSelectionChangedDataLayer, TagManagerEvent } from '../gtm/types';
import { triggerGtmEvent } from '../gtm/triggerGtmEvent';

const mapOption = (option: string): string => {
    if (option === 'login') {
        return 'Login';
    }
    if (option === 'register') {
        return 'Registration';
    }
    if (option === 'guest') {
        return 'Guest';
    }

    return option;
};

type CheckoutAccountSelectionData = {
    step: 2;
    optionType: 'Account';
    option: string;
};

const createGtmDataLayer = (
    event: TagManagerEvent,
    data: CheckoutAccountSelectionData
): CheckoutAccountSelectionChangedDataLayer => ({
        event,
        ecommerce: {
            checkoutOption: {
                actionField: {
                    ...data,
                    option: mapOption(data.option),
                },
            },
        },
});

export const dispatchCheckoutAccountChanged = (data: CheckoutAccountSelectionData): void => {
    triggerGtmEvent(createGtmDataLayer(ANALYTICS_EVENT.CHECKOUT_ACCOUNT, data));
};
