import { injectComponent } from '@mediashop/app/component-injector';
import { ReactElement } from 'react';
import * as React from 'react';
import { BaseProps, BrxAnchor, BrxImageSetImpl } from '@mediashop/app/bloomreach/types';
import InfoBannerComponent from './InfoBannerCommon';

export type InfoBannerCommonProps = BaseProps & {
    anchor?: BrxAnchor;
    backgroundColor?: string;
    textColor?: string;
    dismissalKey: string;
    icon?: BrxImageSetImpl;
    showCloseTrigger: boolean;
    style: 'primary' | 'secondary' | 'tertiary' | 'default' | 'info' | 'warning' | 'error' | 'success';
    text: React.ReactElement;
};

const InfoBannerCommon = (props: InfoBannerCommonProps): ReactElement => <InfoBannerComponent {...props} />;

export default injectComponent('pattern.molecule.InfoBannerCommon', InfoBannerCommon);
