import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { lazy, ReactElement } from 'react';

const CustomerClubUserInfoLazy = lazy(() => lazyRetry(() => import('./CustomerClubUserInfo')));

export type CustomerClubUserInfoProps = BaseProps & {
    ctaTextMembership: string;
    image?: BrxImageWithAltText;
};

const CustomerClubUserInfo = (props: CustomerClubUserInfoProps): ReactElement => (
    <LazyComponent>
        <CustomerClubUserInfoLazy {...props} />
    </LazyComponent>
);

export default CustomerClubUserInfo;
