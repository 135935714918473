import classNames from 'classnames';
import { BasePropsWithChildren } from '@mediashop/app/bloomreach/types';

type AvailableContentSpaceProps = BasePropsWithChildren & {
    contentSpace: string;
};

const componentName = 'available-content-space';

export default function AvailableContentSpace({ children, contentSpace }: AvailableContentSpaceProps): JSX.Element {
    const contentWidth = contentSpace?.length ? contentSpace : '100';
    return <div className={classNames(componentName, `${componentName}--width-${contentWidth}`)}>{children}</div>;
}
