import { BaseProps, BrxImageWithAltText } from '@mediashop/app/bloomreach/types';
import { BreakpointName } from '@mediashop/app/config/breakpoints';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { ReactElement, useEffect, useState } from 'react';
import { BrXImageVariant } from '../../../types/imageVariants';
import BloomreachImageResponsive from '../BloomreachImageResponsive';
import BloomreachVideo from '../BloomreachVideo';

type BloomreachAssetsProps = BaseProps & {
    altText: string;
    mobileImage?: BrxImageWithAltText;
    tabletImage?: BrxImageWithAltText;
    desktopImage: BrxImageWithAltText;
    variant: BrXImageVariant;
};

const BloomreachAssets = ({
    altText,
    className,
    desktopImage,
    mobileImage,
    variant,
}: BloomreachAssetsProps): ReactElement | null => {
    const deviceType = useDeviceType();
    const [device, setDevice] = useState<BreakpointName>('desktop');
    useEffect(() => setDevice(deviceType), [deviceType]);

    const isMobile = device === 'mobile';
    const desktopVideoUrl = desktopImage.video?.data.asset.links.site.href;
    const mobileVideoUrl = mobileImage?.video?.data.asset.links.site.href;

    if (desktopVideoUrl || (isMobile && mobileVideoUrl)) {
        const videoUrl = isMobile && mobileVideoUrl ? mobileVideoUrl : desktopVideoUrl;
        return <BloomreachVideo className={`${className}__video`} videoUrl={videoUrl!} />;
    }

    if (desktopImage.image || (isMobile && mobileImage?.image)) {
        return (
            <BloomreachImageResponsive
                className={`${className}__image`}
                alt={altText}
                desktopImage={desktopImage.image}
                mobileImage={mobileImage?.image}
                variant={variant}
            />
        );
    }

    return SKIP_RENDER;
};

export default BloomreachAssets;
