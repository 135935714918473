import { RootStore } from '../store';
import { CartQueryContextParams } from './types/CartQueries';
import { GatewayProductContainer } from './types/GatewayProduct';
import {
    AutoSuggestRequest,
    AutoSuggestResponse,
    GetProductsByIdRequest,
    GetProductsByKeyRequest,
    GetProductsBySkuRequest,
    SearchProductsRequest,
    GetProductsByRecommendationRequest,
    GetUserManualsRequest,
    GetUserManualsResponse,
    GetWkoProductsRequest,
    SearchProductsResponse,
    GetProductsByRecommendationResponse,
    GetWkoProductsResponse,
    QueryProductsRequest,
    QueryProductsResponse,
    GetProductBySlugResponse,
    GetProductBySlugRequest,
    GetProductsResponse,
    GetProductPricesBySkuRequest,
    GetProductPricesBySkuResponse,
    SearchByAttributeRequest,
    SearchByAttributeResponse,
    SearchByAttributeFilter,
} from './types/ProductQueries';
import ProductMapper from '../bloomreach/product/ProductMapper';
import { Product, ProductWithDeeplink } from './types/ClientProduct';
import { NeededPriceType } from './types/PriceType';
import { ReviewsRequest, ReviewsResponse } from './types/ReviewQueries';
import { DetailedProduct } from './types/ClientProductDetailed';
import { IProductClient } from './IProductClient';
import { UserManual } from './types/UserManual';
import ApiClient from './ApiClient';

/**
 * API-Client handling the product-domain.
 */
export default class ProductClient implements IProductClient {
    store: RootStore;
    apiClient: ApiClient;

    constructor(apiClient: ApiClient, store: RootStore) {
        this.apiClient = apiClient;
        this.store = store;
    }

    private getContextParams(): CartQueryContextParams {
        const { context } = this.store.getState();
        return {
            currency: context.currency,
            country: context.country,
            locale: context.locale,
        };
    }

    private mapProduct(container: GatewayProductContainer) {
        return ProductMapper.mapProduct(container, this.store.getState().context);
    }

    private mapNestJsProduct(product: DetailedProduct) {
        return ProductMapper.mapNestJsProduct(product, this.store.getState().context);
    }

    async getProductsById(ids: string[], neededPriceType?: NeededPriceType, relatedSku?: string): Promise<Product[]> {
        if (ids.length === 0) {
            return [];
        }

        const { context } = this.store.getState();
        const parameters: GetProductsByIdRequest = {
            ids,
            currency: context.currency,
            locale: context.locale,
            country: context.country,
        };
        if (neededPriceType) {
            parameters.neededPriceType = neededPriceType;
        }
        if (relatedSku) {
            parameters.relatedSku = relatedSku;
        }

        const result = await this.apiClient.query<GetProductsByIdRequest, GetProductsResponse>(
            'GetProductsById',
            parameters
        );

        return result.products;
    }

    async getProductsBySku(
        skus: string[],
        locale: string,
        country: string,
        currency: string,
        neededPriceType?: NeededPriceType
    ): Promise<Product[]> {
        if (!skus.length) {
            return [];
        }

        const parameters: GetProductsBySkuRequest = {
            skus,
            currency,
            locale,
            country,
        };

        if (neededPriceType) {
            parameters.neededPriceType = neededPriceType;
        }

        const result = await this.apiClient.query<GetProductsBySkuRequest, GetProductsResponse>(
            'GetProductsBySku',
            parameters
        );

        return result.products;
    }

    async getProductsByKey(keys: string[], neededPriceType?: NeededPriceType, relatedSku?: string): Promise<Product[]> {
        if (!keys.length) {
            return [];
        }

        const {
            context: { currency, locale, country },
        } = this.store.getState();

        const parameters: GetProductsByKeyRequest = {
            keys,
            currency,
            locale,
            country,
        };

        if (neededPriceType) {
            parameters.neededPriceType = neededPriceType;
        }
        if (relatedSku) {
            parameters.relatedSku = relatedSku;
        }

        const result = await this.apiClient.query<GetProductsByKeyRequest, GetProductsResponse>(
            'GetProductsByKey',
            parameters
        );
        return result.products;
    }

    async getProductBySlug(
        slug: string,
        neededPriceType?: NeededPriceType,
        relatedSku?: string
    ): Promise<DetailedProduct | undefined> {
        const { context } = this.store.getState();

        const parameters: GetProductBySlugRequest = {
            slug,
            currency: context.currency,
            locale: context.locale,
            country: context.country,
            neededPriceType,
            relatedSku,
        };

        if (neededPriceType) {
            parameters.neededPriceType = neededPriceType;
        }
        if (relatedSku) {
            parameters.relatedSku = relatedSku;
        }

        try {
            const result = await this.apiClient.query<GetProductBySlugRequest, GetProductBySlugResponse>(
                'GetProductBySlug',
                parameters
            );
            return this.mapNestJsProduct(result.product);
        } catch {
            return undefined;
        }
    }

    searchByAttribute(
        filters: Array<SearchByAttributeFilter>,
        country: string,
        currency: string,
        locale: string,
        limit: number,
        offset: number
    ) {
        return this.apiClient.query<SearchByAttributeRequest, SearchByAttributeResponse>('SearchByAttribute', {
            filters,
            country,
            currency,
            locale,
            offset,
            limit,
        });
    }

    searchProducts(
        searchTerm: string,
        country: string,
        currency: string,
        locale: string,
        limit: number,
        offset: number
    ) {
        return this.apiClient.query<SearchProductsRequest, SearchProductsResponse>('SearchByKeyword', {
            query: searchTerm,
            country,
            currency,
            locale,
            offset,
            limit,
        });
    }

    getAutoCompleteSuggestions(query: string, country: string, currency: string, locale: string, limit: number) {
        return this.apiClient.query<AutoSuggestRequest, AutoSuggestResponse>('GetAutoSuggest', {
            query,
            country,
            currency,
            locale,
            limit,
        });
    }

    /**
     * Get product reviews per SKU
     * @param sku sku of the product
     * @param count number of product to load
     * @param offset offset for pagination
     * @returns list of reviews
     */
    async getReviews(sku: string, count: number, offset: number): Promise<ReviewsResponse> {
        const {
            context: { locale },
        } = this.store.getState();
        const reviews = await this.apiClient.query<ReviewsRequest, ReviewsResponse>('GetReviews', {
            sku,
            count,
            offset,
            locale,
        });
        return reviews;
    }

    /**
     * Get recommended products for bounce-commerce exit page
     * @returns list of products
     */
    async getRecommendedProducts(sku?: string): Promise<ProductWithDeeplink[]> {
        const {
            context: { currency, locale, country },
        } = this.store.getState();

        const parameters: GetProductsByRecommendationRequest = {
            currency,
            locale,
            country,
        };

        if (sku) {
            parameters.sku = sku;
        }

        const result = await this.apiClient.query<
            GetProductsByRecommendationRequest,
            GetProductsByRecommendationResponse
        >('GetProductsByRecommendations', parameters);

        return result.products.map((product) => {
            let deepLink;
            Object.keys(result.deepLinks).forEach((sku) => {
                const variant = product.variants.find((variant) => variant.sku === sku);
                if (variant?.sku) {
                    deepLink = result.deepLinks[variant.sku];
                }
            });

            return {
                ...product,
                deepLink,
            };
        });
    }

    async getProductPrices(
        sku: string,
        neededPriceType: NeededPriceType,
        relatedSku?: string
    ): Promise<GetProductPricesBySkuResponse> {
        const {
            context: { country },
        } = this.store.getState();

        const prices = await this.apiClient.query<GetProductPricesBySkuRequest, GetProductPricesBySkuResponse>(
            'GetProductPricesBySku',
            {
                sku,
                country,
                neededPriceType,
                relatedSku,
            }
        );

        return prices;
    }

    async getUserManuals(): Promise<UserManual[]> {
        const { locale } = this.getContextParams();

        const manuals = await this.apiClient.query<GetUserManualsRequest, GetUserManualsResponse>('GetUserManuals', {
            locale,
        });

        return manuals.manuals;
    }

    async getWkoSuggestion() {
        const { currency, locale } = this.getContextParams();
        return await this.apiClient.query<GetWkoProductsRequest, GetWkoProductsResponse>('GetWkoSuggestion', {
            currency,
            locale,
        });
    }

    async queryProducts(identifiers: string[]): Promise<QueryProductsResponse> {
        if (!identifiers.length) {
            return { products: {} };
        }

        const {
            context: { currency, locale, country },
        } = this.store.getState();

        const response = await this.apiClient.query<QueryProductsRequest, QueryProductsResponse>('QueryProducts', {
            identifiers,
            locale,
            country,
            currency,
        });

        const data = ProductMapper.enrichProductResponseWithActiveVariant(response);
        return ProductMapper.enrichQueryProductsVariantWithContext(data, { currency, locale, country });
    }
}
