import { useMemo, useState } from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import DynamicFormik from '@mediashop/base/pattern/molecule/DynamicFormik';
import { FormattedMessage } from 'react-intl';
import { FormikHelpers } from 'formik';
import { useAddressConfig } from '@mediashop/app/queries/addresses/useAddressConfig';
import Button from '@mediashop/base/pattern/atom/Button';
import { Customer } from '@mediashop/app/api/types/ClientCustomer';
import { getInitialFormValues } from './getInitialFormValues';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';

const componentName = 'personal-info-address-form';

export type PersonalInfoAddressFormValues = Record<string, string | undefined>;

type PersonalInfoAddressesProps = BaseProps & {
    customer: Customer;
    onSubmit: (
        values: PersonalInfoAddressFormValues,
        formikHelpers: FormikHelpers<PersonalInfoAddressFormValues>
    ) => void;
    isSubmitting: boolean;
};

export function PersonalInfoAddressForm({
    className,
    customer,
    onSubmit,
    isSubmitting,
}: PersonalInfoAddressesProps): JSX.Element {
    const { country } = useShopContext();
    const { shippingCountries } = useProject();

    const [form, setForm] = useState<PersonalInfoAddressFormValues | undefined>();

    const defaultValues = useMemo(() => {
        const initialValues = getInitialFormValues(customer, shippingCountries, country);
        setForm(initialValues);
        return initialValues;
    }, []);

    const addressConfig = useAddressConfig(form?.country ?? defaultValues.country!);

    const handleFormChange = ({ values }) => {
        setForm(() => {
            if (form?.postalCode && !values.postalCode) {
                values.postalCode = form.postalCode;
            } else if (values.country !== form?.country) {
                values.postalCode = '';
            }

            return { ...defaultValues, ...values };
        });
    };

    const memoizedForm = useMemo(
        () => (
            <DynamicFormik<PersonalInfoAddressFormValues>
                defaultValues={form}
                formFieldSets={addressConfig?.personalinfo?.fieldsets}
                errorFocus
                onSubmit={onSubmit}
                onChange={handleFormChange}
                submitButton={
                    <Button
                        style="tertiary"
                        className={`${componentName}__submit`}
                        fullWidth
                        type="submit"
                        loading={isSubmitting}
                    >
                        <FormattedMessage id="register.change" />
                    </Button>
                }
            />
        ),
        [addressConfig, defaultValues, isSubmitting, onSubmit, form]
    );

    return (
        <div className={`${componentName} registration__panel ${className}`}>
            <div className={`${componentName}__hint`}>
                <FormattedMessage id="address.requiredField" />
            </div>
            {memoizedForm}
        </div>
    );
}
