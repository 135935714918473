import * as React from 'react';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import LazyComponent, { lazyRetry } from '@mediashop/base/pattern/molecule/LazyComponent';
import { Customer } from '@mediashop/app/api/types/ClientCustomer';
import { Cart } from '@mediashop/app/api/types/ClientCart';

export type CheckoutLoginBillingProps = BaseProps & {
    cart?: Cart;
    customer: Customer;
    isCountryCompatible: boolean;
    isLoading?: boolean;
    onSubmit: (values: Record<string, string>) => void;
    refId?: React.ForwardedRef<{ onSubmit: () => void }>;
};

const CheckoutLoginBillingMolecule = React.lazy(() => lazyRetry(() => import('./BillingForm')));

const CheckoutLoginBillingForm = (props: CheckoutLoginBillingProps): JSX.Element => (
    <LazyComponent>
        <CheckoutLoginBillingMolecule {...props} />
    </LazyComponent>
);

export default CheckoutLoginBillingForm;
