import Theme from '@mediashop/app/Theme';
import CatalogBaseTheme from '@mediashop/catalog-base';
import './pattern/template/Meta/index.tsx';

const MediashopTheme: Theme = {
    name: 'mediashop',
    extends: CatalogBaseTheme,
};

export default MediashopTheme;
