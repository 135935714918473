import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import split from 'lodash/split';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import ContentWrapper from '../../../../base/pattern/atom/ContentWrapper';
import Icon from '../../../../base/pattern/atom/Icon';
import { hyphenToUnderscore } from '@mediashop/catalog-base/utils/hyphenToUnderscore';
import { TrackableLink } from '@mediashop/app/analytics/components/TrackableLink';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { useIntl } from 'react-intl';
import useBreadcrumbs from '@mediashop/catalog-base/hooks/useBreadcrumbs';
import { CategoryBase } from '@mediashop/app/api/types/ClientCategory';
import BreadcrumbItem from './BreadcrumbItem';
import { BreadcrumbSeo } from './SEO';
import { FlatBreadcrumbIndex } from '../../../utils/buildBreadcrumbsIndex';

const componentName = 'breadcrumb';

type Breadcrumb = { key: string; label: string; link: string };

const createBreadcrumbFromCategory = (category: CategoryBase): Breadcrumb => ({
    key: hyphenToUnderscore(category.slug),
    label: category.name,
    link: category.slug,
});

const createBreadcrumbFromRoute = (route: string, breadcrumbIndex: FlatBreadcrumbIndex): Breadcrumb => ({
    key: hyphenToUnderscore(route),
    label: breadcrumbIndex[hyphenToUnderscore(route)]?.name,
    link: route,
});

/**
 * Render Breadcrumbs.
 */
// eslint-disable-next-line max-lines-per-function, complexity
function Breadcrumb() {
    const intl = useIntl();
    const { formatLinkBase } = useReactRouterLink();

    const { product: slugProduct } = useGetProductBySlug();

    const { route, prevRoute } = useShopContext();

    const routeArray = split((route as string).substring(1), '/');
    const prevRouteArray = split((prevRoute as string).substring(1), '/');

    const isProductPage = includes(routeArray, 'p');
    const fromCategoryPage = includes(prevRouteArray, 'c');
    const homeLink = formatLinkBase(EMPTY_STRING);
    const homeIconAriaLabel = intl.formatMessage({ id: 'category.breadcrumbHomeAriaLabel' });

    const { flatBreadcrumbIndex: breadcrumbIndex, nestedBreadcrumbIndex } = useBreadcrumbs();

    const firstProductMainCategory = slugProduct?.product?.categories?.find(
        (cat) => nestedBreadcrumbIndex[hyphenToUnderscore(cat.slug)]
    );

    const firstProductSubCategory = slugProduct?.product?.categories?.find((cat) => {
        const mainCategoryFromIndex =
            nestedBreadcrumbIndex[hyphenToUnderscore(firstProductMainCategory?.slug ?? EMPTY_STRING)];
        return mainCategoryFromIndex?.children?.[hyphenToUnderscore(cat.slug)];
    });

    const getBreadCrumbs = (routes: Breadcrumb[]) => (
        <>
            {map(routes, (route, ind) => {
                if (ind >= routes.length - 1) {
                    return (
                        <BreadcrumbItem key={route.key}>
                            <span
                                className={classNames(`${componentName}-item__link`, {
                                    [`${componentName}-item__current-product`]: isProductPage,
                                })}
                            >
                                {route.label}
                            </span>
                        </BreadcrumbItem>
                    );
                }

                return (
                    <BreadcrumbItem key={route.key}>
                        <Link
                            className={`${componentName}-item__link`}
                            to={formatLinkBase(
                                routes[ind - 1] ? `c/${routes[ind - 1].link}/${route.link}` : `c/${route.link}`
                            )}
                        >
                            {route.label}
                        </Link>
                    </BreadcrumbItem>
                );
            })}
        </>
    );

    const createBreadcrumbFromProduct = () => {
        const breadcrumbs: Breadcrumb[] = [];

        if (firstProductMainCategory) {
            breadcrumbs.push(createBreadcrumbFromCategory(firstProductMainCategory));
        }
        if (firstProductSubCategory) {
            breadcrumbs.push(createBreadcrumbFromCategory(firstProductSubCategory));
        }
        breadcrumbs.push({
            key: slugProduct?.product?.key ?? '',
            label: slugProduct?.product?.name ?? '',
            link: slugProduct?.product?.slug ?? '',
        });

        return (
            <>
                {slugProduct?.product ? (
                    <BreadcrumbSeo breadcrumbIndex={breadcrumbIndex} product={slugProduct?.product} />
                ) : (
                    SKIP_RENDER
                )}

                <ContentWrapper>
                    <div className={`${componentName}`}>
                        <BreadcrumbItem showIcon={false}>
                            <TrackableLink
                                to={homeLink}
                                trackingKey={TrackingKeys.BREADCRUMBS_HOME}
                                aria-label={homeIconAriaLabel}
                            >
                                <Icon name="Home" />
                            </TrackableLink>
                        </BreadcrumbItem>
                        {getBreadCrumbs(breadcrumbs)}
                    </div>
                </ContentWrapper>
            </>
        );
    };

    const createBreadcrumbFromPrevRoute = () => {
        const routes = prevRouteArray.slice(prevRouteArray.findIndex((el) => el === 'c') + 1);

        if (slugProduct?.product && !slugProduct?.product?.categories?.some((cat) => cat.slug === routes[0])) {
            return createBreadcrumbFromProduct();
        }

        const breadcrumbs = routes.map((route) => createBreadcrumbFromRoute(route, breadcrumbIndex));
        breadcrumbs.push({
            key: slugProduct?.product?.key ?? '',
            label: slugProduct?.product?.name ?? '',
            link: slugProduct?.product?.slug ?? '',
        });

        return (
            <>
                {slugProduct?.product && (
                    <BreadcrumbSeo breadcrumbIndex={breadcrumbIndex} product={slugProduct?.product} />
                )}

                <ContentWrapper>
                    <div className={`${componentName}`}>
                        <BreadcrumbItem showIcon={false}>
                            <TrackableLink
                                to={homeLink}
                                trackingKey={TrackingKeys.BREADCRUMBS_HOME}
                                aria-label={homeIconAriaLabel}
                            >
                                <Icon name="Home" />
                            </TrackableLink>
                        </BreadcrumbItem>
                        {getBreadCrumbs(breadcrumbs)}
                    </div>
                </ContentWrapper>
            </>
        );
    };

    if (isProductPage) {
        if (!fromCategoryPage) {
            return createBreadcrumbFromProduct();
        }
        return createBreadcrumbFromPrevRoute();
    }

    const routes = routeArray.slice(routeArray.findIndex((el) => el === 'c') + 1);
    const breadcrumbs = routes.map((route) => createBreadcrumbFromRoute(route, breadcrumbIndex));

    return (
        <>
            {slugProduct?.product && <BreadcrumbSeo breadcrumbIndex={breadcrumbIndex} product={slugProduct?.product} />}

            <ContentWrapper>
                <div className={`${componentName}`}>
                    <BreadcrumbItem showIcon={false}>
                        <TrackableLink
                            to={homeLink}
                            trackingKey={TrackingKeys.BREADCRUMBS_HOME}
                            aria-label={homeIconAriaLabel}
                        >
                            <Icon name="Home" />
                        </TrackableLink>
                    </BreadcrumbItem>
                    {getBreadCrumbs(breadcrumbs)}
                </div>
            </ContentWrapper>
        </>
    );
}

export default injectComponent('pattern.molecule.Breadcrumb', Breadcrumb, 'catalog-base');
