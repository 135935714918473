import { Coordinates } from './types';

const getImageMeta = (url: string): Promise<{ width: number; height: number }> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Could not load image'));
        img.src = url;
    });

const getClippedPercentage = (absoluteCoordinatePosition: number, dimension: number): number =>
    Math.floor((Math.min(dimension, Math.max(absoluteCoordinatePosition, 0)) / dimension) * 100);

export const getResponsiveCoordinates = async (pxCoordinates: Coordinates, url: string): Promise<Coordinates> => {
    const meta = await getImageMeta(url);
    return {
        left: getClippedPercentage(pxCoordinates.left, meta.width),
        top: getClippedPercentage(pxCoordinates.top, meta.height),
    };
};

export const getCoordinatesFromBrxString = (coordinatesString: string | undefined): Coordinates | undefined => {
    if (!coordinatesString) {
        return undefined;
    }

    const parsed = JSON.parse(coordinatesString) as number[];
    return {
        left: parsed.length > 0 ? parsed[0] : 0,
        top: parsed.length > 1 ? parsed[1] : 0,
    };
};
